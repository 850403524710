export default {
  locale: 'fi',
  messages: {
    siteTitle: 'Draft-hakulomake',
    switchLanguage: ' ',
    draftFormInfo: 'Ohjeet',
    draftApplication: 'Hakemuksen tiedot',
    draftCompetitionTitle: 'Hakukohde',
    draftCompetitionDefault: 'Valitse hakukohde, johon olet hakemassa',
    draftCompetitionInvalid: 'Valitse hakukohde. Jos valittavana ei ole hakukohteita, ei käynnissä ole yhtään Draft -hakua.',
    inputTooLong: 'Syöte on liian pitkä.',
    draftContactDetails: 'Yhteystiedot',
    draftContactInfoText: 'Vähintään yhden tiimiläisen on oltava opiskelija, alumni tai henkilökunnan edustaja Karelia-ammattikorkeakoulussa, ' +
        'Itä-Suomen yliopistossa tai Pohjois-Karjalan koulutuskuntayhtymä Riveriassa. Lomakkeeseen tulee syöttää kyseisen henkilön tiedot.',
    draftSenderName: 'Etu- ja sukunimi',
    draftSenderNameInvalid: 'Etu- ja sukunimien pituuden on oltava yhteensä vähintään 5 merkkiä.',
    draftSenderEmail: 'Sähköpostiosoite',
    draftSenderEmailInvalid: 'Tarkista sähköpostiosoite.',
    draftSenderConfirmEmail: 'Sähköpostin vahvistus',
    draftSenderConfirmEmailInvalid: 'Sähköpostiosoitteet eivät täsmää.',
    draftSenderPhone: 'Puhelinnumero',
    draftSenderPhoneInvalid: 'Puhelinnumerossa tulee olla vähintään 6 merkkiä ja 3 perättäistä numeroa.',
    draftSenderSchool: 'Oppilaitos ja rooli',
    karelia: 'Karelia-ammattikorkeakoulu',
    uef: 'Itä-Suomen yliopisto',
    riveria: 'Pohjois-Karjalan koulutuskuntayhtymä Riveria',
    draftSenderSchoolInvalid: 'Kirjoita kenttään oppilaitos, jota edustat.',
    draftSenderRole: 'Rooli',
    draftSenderStudent: 'Opiskelija',
    draftSenderStaff: 'Henkilökunta',
    draftSenderAlumni: 'Alumni',
    draftSenderStudentOrAlumni: 'Koulutusala',
    draftSenderStaffPosition: 'Ammatti',
    draftSenderOrganisationInvalid: 'Kirjoita oma vastauksesi, tiimiläisistä ei tarvitse kertoa.',
    draftMembers: 'Tiimin koko',
    otherMembers: 'muut tiimiläiset',
    draftMembersDefault: 'Valitse tiimiläisten määrä',
    persons: 'henkilöä',
    teamMembers: 'tiimiläistä.',
    teamMember: 'tiimiläinen.',
    draftMembersInvalid: 'Osallistuminen vaatii vähintään kahden hengen tiimin.',
    draftTitle: 'Idean nimi',
    draftTitleInvalid: 'Idean otsikon on oltava vähintään 5 merkkiä pitkä.',
    draftDescription: 'Idean kuvaus ',
    draftDescriptionInvalid: 'Idean kuvaus ei ole tarpeeksi pitkä.',
    draftHeardFrom: 'Mistä kuulit Draft-ohjelmasta?',
    draftHeardFromInvalid: 'Kerro miten sait tietää Draftista.',
    draftPdfLabel: 'Liitetiedosto',
    draftPdfInfo: 'Pudota tiedosto tähän tai avaa tiedoston valinta klikkaamalla.',
    draftPdfExtension: 'Ainoastaan *.pdf tiedostot hyväksytään.',
    draftPdfRejected: 'Virheellinen tiedosto',
    draftPdfRejectedAlert: 'Tiedosto hylättiin, sen koko on liian suuri tai se ei ole kelvollinen .pdf-tiedosto.',
    draftFinalize: 'Viimeistely',
    draftTerms: 'Hyväksyn palvelun käyttöehdot ja suojauskäytännöt.',
    termsModalTitle: 'Käyttöehdot ja suojauskäytännöt',
    termsTitle: 'Käyttöehdot',
    cookiesTitle: 'Suojauskäytännöt',
    cookiesInfo: 'Osa sivuston toiminnallisuudesta on kytköksissä evästeisiin.\n\n' +
    'Googlen evästeitä tarvitaan reCAPTCHA palvelua varten, joka suojelee lomaketta robotteja vastaan.\n' +
    'Lisätietoa näistä evästeistä saa reCAPTCHA logoon liitettyjen linkkien kautta.\n\n' +
    'TinyMCE on tekstieditori, jota käytetään kuvauksen kirjoittamiseen. TinyMCE käyttää evästeitä tekstikentän ' +
    'koon dynaamiseen muuttamiseen.',
    submit: 'Lähetä',
    formIncomplete: 'Tarkista, että kaikki kentät on täytetty oikein.',
    submitError: 'Hakemuksen lähettämisessä tapahtui virhe. Yritä lähettää hakemus uudestaan.',
    competitionError: 'Virhe! Valittua hakukohdetta ei löydetty. Hakukohde on ilmeisesti poistettu sillä välin, kun hakemusta täytettiin.',
    descriptionTooLong: 'Hakemusta ei lähetetty! Kuvaus on liian pitkä ja ylittää tekstieditorin 75000 merkin rajan.',
    close: 'Sulje',
    formSubmitted: 'Hakemus lähetetty',
    formSentText: 'Hakemuksesi lähetettiin onnistuneesti. Saat sähköpostiisi vielä vahvistusviestin, viesti ei sisällä ' +
    'hakemuksen tietoja.\nJos haluat säilyttää tiedot, ota ne itsellesi nyt talteen.\n' +
    'Mikäli huomaat annetuissa tiedoissa virheen, ota yhteyttä: ',
  },
};
