import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, UncontrolledTooltip } from 'reactstrap';
import { sendInstructionEmailRequest } from '../../../actions/ManagementDraftActions';
const FileSaver = require('file-saver');
const b64toBlob = require('b64-to-blob');

// This is used to show the sent form details in the draftCreateWidget.
export class SingleDraftDetails extends PureComponent {
    render() {
        // Generates a .pdf file from base64 and downloads it.
        const downloadPdf = function (base64Data, fileName) {
            const blob = b64toBlob(base64Data, 'application/pdf');
            FileSaver.saveAs(blob, fileName);
        };
        return (
            <div>
                <section className="sent-section">
                    <div className="sent-description">
                        <p className="sent-text-item heard-from-management"><span id="sentHeardFrom" className="sent-text-item">
                            <i className="fas fa-question-circle label-icon" /> {this.props.form.heardFrom}</span></p>
                        <UncontrolledTooltip placement="bottom-start" hideArrow target="sentHeardFrom">
                            Sai tiedon
                        </UncontrolledTooltip>
                        <p className="sent-text-item"><span id="sentCompetition" className="sent-text-item">
                            <i className="fas fa-bullseye label-icon" /> {this.props.form.competitionName}</span></p>
                        <p className="sent-text-item"><span id="sentTime" className="sent-text-item">
                            <i className="fas fa-clock label-icon" /> {this.props.form.sentDate}</span></p>
                        <UncontrolledTooltip placement="bottom-start" hideArrow target="sentTime">
                            Lähetysaika
                        </UncontrolledTooltip>
                        <p className="sent-text-item"><span id="sentTitle" className="sent-text-item">
                            <i className="fas fa-pencil-alt label-icon" /> {this.props.form.title}</span></p>
                        {this.props.form.fileName.length > 0 &&
                        <p className="sent-text-item" onClick={() => downloadPdf(this.props.form.fileData,
                            this.props.form.fileName)}
                        ><a id="sentPdf" tabIndex={0} className="sent-text-item"><i className="fas fa-paperclip label-icon" /> {this.props.form.fileName}</a>
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentPdf">
                                Lataa liitetiedosto klikkaamalla.
                            </UncontrolledTooltip>
                        </p>
                        }
                        <div id="sentDescription" className="sent-text-item" tabIndex={0}>
                            <i className="fas fa-lightbulb label-icon" />
                            <div className="sent-description-text" dangerouslySetInnerHTML={{ __html: this.props.form.description }} />
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentDescription">
                                Idean kuvaus.
                            </UncontrolledTooltip>
                        </div>
                    </div>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target="sentCompetition">
                        Hakukohde
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target="sentTitle">
                        Idean nimi
                    </UncontrolledTooltip>
                </section>
                <section className="sent-section">
                    <div className="sent-contact">
                        <p tabIndex={0} className="sent-text-item"><span id="sentTeam" className="sent-text-item"><i className="fas fa-users label-icon" />
                            {this.props.form.name} + {this.props.form.matesQuantity} { this.props.form.matesQuantity === 1 ? 'tiimiläinen' :
                                'tiimiläistä' } </span></p>
                        <UncontrolledTooltip placement="bottom-start" hideArrow target="sentTeam">
                            Lähettäjän nimi ja muiden tiimiläisten määrä.
                        </UncontrolledTooltip>
                        <FormGroup>
                            <div tabIndex={0} className="sent-email-phone">
                                <p className="sent-text-item"><span id="sentEmail" className="sent-text-item"><i className="fas fa-envelope label-icon" /> {this.props.form.email}</span></p>
                                {this.props.form.phone !== '' ? <p className="sent-text-item"><span id="sentPhone" className="sent-text-item">
                                        <i className="fas fa-phone-square label-icon" /> {this.props.form.phone}</span></p> : ''}
                            </div>
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentEmail">Sähköposti</UncontrolledTooltip>
                            {this.props.form.phone !== '' ? <UncontrolledTooltip placement="bottom-start" hideArrow target="sentPhone">Puhelin</UncontrolledTooltip> : ''}
                        </FormGroup>
                        {this.props.form.roleText !== '' ? <FormGroup className="sender-school-role school-management row" id="sentSchool">
                            <img alt="Oppilaitoksen logo" src={this.props.form.schoolImgSrc} />
                            <p className="sent-text-item role-text">{this.props.form.roleText}</p>
                        </FormGroup> : <div><p className="sent-text-item"><span className="sent-text-item" id="sentSchool"> <i className="fas fa-school label-icon" /> {this.props.form.organisation}</span></p>
                        </div>
                        }
                        <UncontrolledTooltip target="sentSchool" placement="bottom-start" hideArrow>
                            Oppilaitos ja rooli
                        </UncontrolledTooltip>
                    </div>
                </section>
                <FormGroup id="feedbacks">
                    {this.props.form.feedbackList.length !== 0 && this.props.form.feedbackList != null ?
                        <h3 className="feedbacks-title"><i className="feedback-th-icon fas fa-comment label-icon" /> Palautteet</h3> :
                        <h3 className="feedbacks-title"><i className="feedback-th-icon fas fa-comment label-icon" /> Ei palautteita</h3>}
                    {Object.keys(this.props.form.feedbackList).map(i =>
                        (
                            <div key={i}>
                            <FormGroup>
                                <div className="single-feedback" tabIndex={0} dangerouslySetInnerHTML={{ __html: this.props.form.feedbackList[i].toString() }} />
                            </FormGroup>
                        </div>)
                    )}
                </FormGroup>
            </div>
        );
    }
}

SingleDraftDetails.propTypes = {
    form: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ DraftActions: state.DraftActions });

export default connect(mapStateToProps)(SingleDraftDetails);
