// Initial state
const initialState = {
    id: '',
    isLoggedIn: false,
    isLoggingIn: false,
    loginAttempts: 0,
    loginWaitTime: 0,
    lastPasswordReset: undefined,
    isAdmin: false,
    emailNotifications: true,
    email: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'AUTHENTICATION_LOGIN_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.lastPasswordReset = undefined;
            newState.isLoggingIn = true;
            newState.loginAttempts = 0;
            newState.loginWaitTime = 0;
            return newState;
        }
        case 'AUTHENTICATION_LOGIN_FAILURE': {
            const newState = Object.assign({}, state);
            newState.isLoggingIn = false;
            return newState;
        }
        case 'AUTHENTICATION_LOGIN_INCREASE': {
            const newState = Object.assign({}, state);
            if (action.json.waitTime) {
                newState.loginWaitTime = action.json.waitTime;
                newState.loginAttempts = action.json.loginAttempts;
            }
            return newState;
        }
        case 'AUTHENTICATION_SESSION_CHECK_FAILURE':
        case 'AUTHENTICATION_LOGOUT_SUCCESS': {
            return Object.assign({}, initialState);
        }
        case 'AUTHENTICATION_LOGIN_SUCCESS':
        case 'AUTHENTICATION_SESSION_CHECK_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.id = action.json._id;
            newState.isLoggedIn = true;
            newState.lastPasswordReset = action.json.lastPasswordReset;
            newState.email = action.json.email;
            newState.isAdmin = action.json.isAdmin;
            newState.isAdmin = action.json.isAdmin;
            newState.id = action.json.id;
            newState.emailNotifications = action.json.emailNotifications;
            return newState;
        }
        case 'AUTHENTICATION_LOGOUT_FAILURE':
        default: {
            return state;
        }
    }
}
