import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, Label, ModalBody, ModalFooter } from 'reactstrap';
// Import actions
import { checkSession } from '../../../../actions/AuthenticationActions';
import { addCompetition } from '../../../../actions/CompetitionActions';
import CompetitionListPage from './competitionList';

class CompetitionManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNewCompetitionModalVisible: false,
            newCompetitionFi: '',
            newCompetitionEn: '',
            isCompetitionListModalVisible: false,
            isSuccessModalVisible: false,
        };
        this.checkUserSession = this.checkUserSession.bind(this);
        this.handleNewCompetitionInput = this.handleNewCompetitionInput.bind(this);
        this.toggleNewCompetitionModal = this.toggleNewCompetitionModal.bind(this);
        this.toggleCompetitionListModal = this.toggleCompetitionListModal.bind(this);
        this.addNewCompetition = this.addNewCompetition.bind(this);
        this.toggleNested = this.toggleNested.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.checkUserSession();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // If new competition is added.
        if (nextProps.competitions.addCompetitionStatus !== this.props.competitions.addCompetitionStatus) {
            if (nextProps.competitions.addCompetitionStatus === 3) {
                this.setState({
                    newCompetitionFi: '',
                    newCompetitionEn: '',
                });
            }
        }
        // Display an alert according to rename results.
        if (nextProps.competitions.competitionUpdateStatus !== this.props.competitions.competitionUpdateStatus) {
            if (nextProps.competitions.competitionUpdateStatus === 2) {
                alert('Hakukohteen nimeämisessä tapahtui virhe, yritä päivitystä uudestaan.');
                window.location.reload(true);
            }
        }
        // Display an alert according to Toggle visibility results.
        if (nextProps.competitions.competitionToggleStatus !== this.props.competitions.competitionToggleStatus) {
            if (nextProps.competitions.competitionToggleStatus === 2) {
                alert('Hakukohteen näkyvyyden muuttamisessa tapahtui virhe, yritä päivitystä uudestaan.');
                window.location.reload(true);
            }
        }
    }

    checkUserSession() {
        const { dispatch } = this.props;
        dispatch(checkSession());
    }

    handleInputFiCompetition(e) {
        this.setState({
            competitionTextFi: e.target.getContent()
        });
    }

    handleInputEnCompetition(e) {
        this.setState({
            competitionTextEn: e.target.getContent()
        });
    }

    handleNewCompetitionInput() {
        this.setState({ newCompetitionFi: this.refs.newCompetitionFi.value });
        this.setState({ newCompetitionEn: this.refs.newCompetitionEn.value });
    }

    toggleNewCompetitionModal() {
        this.checkUserSession();
        this.setState({
            isNewCompetitionModalVisible: !this.state.isNewCompetitionModalVisible
        });
    }


    toggleCompetitionListModal() {
        this.checkUserSession();
        this.setState({
            isCompetitionListModalVisible: !this.state.isCompetitionListModalVisible
        });
    }

    toggleNested() {
        this.setState({
            isSuccessModalVisible: !this.state.isSuccessModalVisible,
        });
    }

    addNewCompetition() {
        this.checkUserSession();
        const formData = this.state;
        // Method to update competition
        const { dispatch } = this.props;
        if (this.state.newCompetitionFi !== '' || this.state.newCompetitionEn !== '') {
            dispatch(addCompetition(formData));
            this.setState({
                isNewCompetitionModalVisible: false,
            });
        }
        else {
            alert('Syötä nimi suomeksi ja englanniksi, tarkista kentät ja yritä uudestaan.');
        }
    }

    // This renders buttons for opening editors for updating form/mail instructions.
    // We are actually passing both competitions to both modals and just re-save the existing data
    // to avoid needing to create own save functions for each.
    // TO DO: Create own save functions for saving/getting competition data from db?
    render() {
        const { competition } = this.props;
        return (<div>
            <p className="settings-category">Hakukohteet</p>
            <Button color="primary" onClick={this.toggleCompetitionListModal}>Hakukohteiden hallinta</Button>
            <Modal className="modal-lg always-lg" isOpen={this.state.isCompetitionListModalVisible}
                   toggle={this.toggleCompetitionListModal} backdrop="static">
                <ModalHeader toggle={this.toggleCompetitionListModal}>Hakukohteet</ModalHeader>
                <ModalBody>
                    <div>
                        <br />
                        <Button color="primary" onClick={this.toggleNewCompetitionModal}>Uusi hakukohde</Button><br /><br />
                        <CompetitionListPage competition={competition} />
                    </div>
                </ModalBody>
                <br />
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleCompetitionListModal}>Sulje</Button>
                </ModalFooter>
            </Modal>
            <Modal className="modal-lg" isOpen={this.state.isNewCompetitionModalVisible} toggle={this.toggleNewCompetitionModal} backdrop="static">
                <ModalHeader toggle={this.toggleNewCompetitionModal}>Hakukohteet</ModalHeader>
                <ModalBody>
                        <div>
                            <Label className="settings-category">Uuden hakukohteen nimi suomeksi</Label>
                            <input type="text" value={this.state.newCompetitionFi} className="form-field"
                                      onChange={this.handleNewCompetitionInput} ref="newCompetitionFi"
                            />
                            <Label className="settings-category">Uuden hakukohteen nimi englanniksi</Label>
                            <input type="text" value={this.state.newCompetitionEn} className="form-field"
                                      onChange={this.handleNewCompetitionInput} ref="newCompetitionEn"
                            />
                        </div>
                    <br />
                    <Button color="info" onClick={this.toggleNewCompetitionModal}>Peruuta</Button>
                    <Button color="success" onClick={this.addNewCompetition}>Tallenna</Button>
                    <br />
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.isSuccessModalVisible} toggle={this.toggleNested}>
                <ModalHeader>Hakukohde lisätty</ModalHeader>
                <br />
                <ModalBody><p>Hakukohde lisättiin onnistuneesti!</p></ModalBody>
                <br />
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleNested}>OK</Button>
                </ModalFooter>
            </Modal>
        </div>);
    }
}

// actions required to provide data for this component to render in sever side.
CompetitionManagement.need = [() => { return addCompetition(); }];

// Retrieve data from store as props
function mapStateToProps(state) {
    return {
        competitions: state.competitions,
    };
}

CompetitionManagement.propTypes = {
    dispatch: PropTypes.func.isRequired,
};


CompetitionManagement.contextTypes = {
    router: PropTypes.object,
};

export default connect(mapStateToProps)(CompetitionManagement);
