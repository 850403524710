import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Formsy from 'formsy-react';
import FormsyInput from '../../../../shared/formsy';
import { savePassword } from '../../../../actions/PassResetActions';

export class ChangePasswordPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            canSubmit: false,
            resultText: '',
        };
        this.sendPassword = this.sendPassword.bind(this);
        this.triggerFormSend = this.triggerFormSend.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.enableButton = this.enableButton.bind(this);
    }

    componentDidMount() {
        document.title = 'Uusi salasana | Draft';
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Check if logging in status has changed.
        if (nextProps.passwordManagement.passwordChangeStatus !== this.props.passwordManagement.passwordChangeStatus) {
            // TO DO: max 6 connections on same browser:
            // https://www.quora.com/How-can-I-fix-the-error-waiting-for-available-sockets-in-Google-Chrome
            const element = document.getElementById('loginPage');
            element.classList.remove('animated');
            element.classList.remove('shake');
            if (nextProps.passwordManagement.passwordChangeStatus === 2) {
                element.classList.add('animated');
                element.classList.add('shake');
                this.setState({
                    resultText: <span className="message"><h5 style={{ color: 'red', marginTop: '10px' }}>Salasanan vaihtaminen epäonnistui.<br />
                        <Link to="/hakemukset/salasanan-palautus"> Lähetä sähköpostiisi uusi palautuslinkki.</Link></h5></span>
                });
            }
            else if (nextProps.passwordManagement.passwordChangeStatus === 3) {
                this.setState({
                    resultText: <h5 style={{ color: 'green', marginTop: '10px' }}>Salasanan vaihtaminen onnistui.
                        Voit nyt kirjautua sisään uudella salasanallasi.</h5>
                });
            }
        }
    }

    triggerFormSend() {
        // Set states from fields that use formsy.
        const formsyValues = this.refs.form.getModel();
        // For some reason, the button is not disabled when the page loads and thus sending empty form is possible.
        if (formsyValues.password) {
            this.setState({
                    password: formsyValues.password,
                    passwordCheck: formsyValues.passwordCheck,
                },
                // Callback to handleValidSubmit once setState is done for.
                this.sendPassword
            );
        }
        else {
            alert('Et voi lähettää tyhjää lomaketta.');
        }
    }

    sendPassword() {
        const { dispatch } = this.props;
        const formData = this.state;
        const data = {
            hash: this.props.match.params.hash,
            formData,
        };
        dispatch(savePassword(data));
    }

    disableButton() {
        this.setState({ canSubmit: false });
    }

    enableButton() {
        this.setState({ canSubmit: true });
    }

    render() {
        const result = this.state.resultText;
        const inputSection = (<Formsy ref="form" onValid={this.enableButton} onInvalid={this.disableButton}>
            <FormsyInput
                name="password"
                message="Salasana"
                type="password"
                validations={{
                    matchRegexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,128})/
                }}
                validationError="Salasanan on oltava 8-128 merkkiä pitkä ja sen tulee
              sisältää vähintään yksi numero sekä pieni ja iso kirjain."
            />
            <FormsyInput
                name="passwordCheck"
                message="Salasana uudestaan"
                type="password"
                validations="equalsField:password"
                validationError="Salasanat eivät täsmää."
            />
            <Button className="button" href="#" target="_self"
                    disabled={!this.state.canSubmit}
                    onClick={this.triggerFormSend}
            >Vaihda salasana</Button>
        </Formsy>);
        return (
            <div className="login-page" id="loginPage">
                <div className="form login-container">
                    {this.props.passwordManagement.passwordChangeStatus !== 3 ?
                        <div>
                        <p>Voit vaihtaa tilisi salasanan kirjoittamalla uuden salasanan kahdesti ja klikkaamalla painiketta.</p>
                        {inputSection}
                        </div>
                        : null }
                    {result}
                    <span className="message">
                    <Link to="/hakemukset/kirjautuminen">Siirry kirjautumissivulle.
                </Link></span>
                    <br />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ passwordManagement: state.passwordManagement });

export default connect(mapStateToProps)(ChangePasswordPageContainer);
