import React from 'react';
import draftLogo from '../../../shared/logos/Draft_logo.svg';
import kareliaLogo from '../../../shared/logos/Karelia_logo.png';
import uefLogo from '../../../shared/logos/UEF_logo.png';
import riveriaLogo from '../../../shared/logos/Riveria_logo.png';

export function Header() {
    return (
        <div className="logo-row">
            <img src={draftLogo} className="col-lg-3 col-md-3 col-sm-6 col-s-12 header-draft-logo" alt="Draft logo" />
            <img src={kareliaLogo} className="col-lg-3 col-md-3 col-sm-6 col-s-12 header-karelia-logo" alt="Karelia logo" />
            <img src={uefLogo} className="col-lg-3 col-md-3 col-sm-6 col-s-12 header-uef-logo" alt="UEF logo" />
            <img src={riveriaLogo} className="col-lg-3 col-md-3 col-sm-6 col-s-12 riveria-logo " alt="Riveria logo" />
        </div>
    );
}

export default Header;
