import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { Editor } from '@tinymce/tinymce-react';
import Recaptcha from 'react-recaptcha';
import { Button, FormGroup, Label, Input, Modal, ModalHeader, ModalFooter, Alert, UncontrolledTooltip, Form } from 'reactstrap';
import Formsy from 'formsy-react';
import FormsyInput from '../../../../shared/formsy';
import { addDraft } from '../../../../actions/EnlistDraftActions';
import { fetchRunningCompetitions } from '../../../../actions/CompetitionActions';
import SentDraftDetails from './SentDraftDetails';
import TermsModal from './Terms';
import kareliaLogo from '../../../../shared/logos/Karelia_logo.png';
import uefLogo from '../../../../shared/logos/UEF_logo.png';
import riveriaLogo from '../../../../shared/logos/Riveria_logo.png';

export class DraftCreateWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            competition: '',
            selectedCompetitionText: '',
            competitionValid: false,
            competitionIsToBeVerifiedOnSubmit: false,
            name: '',
            email: '',
            emailVerification: '',
            phone: '',
            isStudent: true,
            school: 'karelia',
            role: 'student',
            roleText: '',
            roleTextIcon: <i className="fas fa-school label-icon" />,
            organisation: '',
            members: '',
            membersValid: false,
            title: '',
            description: '',
            descriptionPristine: true,
            descriptionValid: false,
            heardFrom: '',
            fileName: '',
            fileData: '',
            captcha: '',
            accepted: [],
            rejected: [],
            submitBtnEnable: true,
            formsyValidationIsValid: false,
            showTermsModal: false,
            showSentFormModal: false,
            termsChecked: false,
            tinyMCELang: 'https://olli-suutari.github.io/tinyMCE-4-translations/fi.js',
            tinyMCELocale: 'fi',
            captchaLocale: 'fi',
            alertVisible: false,
            alertText: '',
        };
        this.fetchRunningCompetitions = this.fetchRunningCompetitions(this);
        this.formsyValidationErrors = this.formsyValidationErrors.bind(this);
        this.formsyValidationOK = this.formsyValidationOK.bind(this);
        this.competitionEdited = this.competitionEdited.bind(this);
        this.senderSchoolChanged = this.senderSchoolChanged.bind(this);
        this.senderRoleChanged = this.senderRoleChanged.bind(this);
        this.membersEdited = this.membersEdited.bind(this);
        this.descriptionEdited = this.descriptionEdited.bind(this);
        this.removeAttachment = this.removeAttachment.bind(this);
        this.toggleTerms = this.toggleTerms.bind(this);
        this.acceptTerms = this.acceptTerms.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.triggerFormSend = this.triggerFormSend.bind(this);
        this.compileFormData = this.compileFormData.bind(this);
        this.reInitTinyMCE = this.reInitTinyMCE.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onDismissAlert = this.onDismissAlert.bind(this);
        this.competitionListItem = this.competitionListItem.bind(this);
    }

    // If url contains /en, modify things to match english. Default locale is determined in src/reducers/IntlReducer.js
    UNSAFE_componentWillMount() {
        if (window.location.href.indexOf('/en') > -1) {
            this.setState({
                tinyMCELang: 'https://olli-suutari.github.io/tinyMCE-4-translations/en_GB.js',
                tinyMCELocale: 'en_GB',
                captchaLocale: 'en-GB',
            });
            document.documentElement.lang = 'en-GB';
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.competitions.competitionList !== this.props.competitions.competitionList) {
            // If we are submitting the form, verify that the competition is still active.
            if (this.state.competitionIsToBeVerifiedOnSubmit) {
                let matchFound = false;
                for (let i = 0; i < nextProps.competitions.competitionList.length; i++) {
                    if (nextProps.competitions.competitionList[i]._id === this.state.competition) {
                        matchFound = true;
                        this.recaptchaInstance.execute();
                    }
                }
                if (!matchFound) {
                    this.setState({
                        alertVisible: true,
                        submitBtnEnable: true,
                        competitionValid: false,
                        competitionIsToBeVerifiedOnSubmit: false,
                        alertText: <FormattedMessage id="competitionError" />
                    });
                }
                this.setState({
                    competitionIsToBeVerifiedOnSubmit: false,
                });
            }
        }
        // Change TinyMCE language
        // See: https://github.com/Olli-Suutari/tinyMCE-4-translations
        // Also change the html lang.
        if (nextProps.intl.locale !== this.props.intl.locale)
        {
            if (nextProps.intl.locale === 'en')
            {
                this.setState({
                        tinyMCELang: 'https://olli-suutari.github.io/tinyMCE-4-translations/en_GB.js',
                        tinyMCELocale: 'en_GB',
                        captchaLocale: 'en-GB',
                    },
                    // Callback to compileFormData once setState is done for.
                    this.reInitTinyMCE
                );
                document.documentElement.lang = 'en-GB';
            }
            else if (nextProps.intl.locale === 'fi')
            {
                this.setState({
                        tinyMCELang: 'https://olli-suutari.github.io/tinyMCE-4-translations/fi.js',
                        tinyMCELocale: 'fi',
                        captchaLocale: 'fi',
                    },
                    // Callback to compileFormData once setState is done for.
                    this.reInitTinyMCE
                );
                document.documentElement.lang = 'fi';
                // Remove /en from url if exists.
                if (window.location.href.indexOf('/en') > -1) {
                    history.pushState({}, null, window.location.href.replace('/en', ''));
                }
            }
        }
        if (nextProps.draft.sendDraftStatus !== this.props.draft.sendDraftStatus)
        {
            if (nextProps.draft.sendDraftStatus === 2) {
                this.setState({
                    alertVisible: true,
                    submitBtnEnable: true,
                    alertText: <FormattedMessage id="submitError" />
                });
            }
            else if (nextProps.draft.sendDraftStatus === 3) {
                this.toggle();
            }
        }
    }

    onSubmit() {
        const matchPadding = /padding-left: \d\d\d/;
        // Show alert if over 75k len in description.
        if (this.state.description.length > 75000)
        {
            this.setState({
                alertVisible: true,
                alertText: (this.props.intl.messages.descriptionTooLong + ' (' + this.state.description.length + ')')
            });
        }
        else
        {
            // If padding is more than 99, replace it with 80 (max 4 idents)
            if (this.state.description.match(matchPadding)) {
                this.setState({
                    description: this.state.description.replace(matchPadding, 'padding-left: 80'),
                });
            }
            this.setState({
                submitBtnEnable: false,
                competitionIsToBeVerifiedOnSubmit: true,
            });
            // Verify that the selected competition still exists...
            const { dispatch } = this.props;
            dispatch(fetchRunningCompetitions());
        }
    }

    onResolved(value) {
        this.setState({
            captcha: value
        });
        this.triggerFormSend();
    }

    onLoad() {
        // Having onload event increases stability of the captcha. If not, the captcha would not always trigger.
    }

    onDismissAlert() {
        this.setState({ alertVisible: false });
    }

    acceptTerms() {
        this.setState({
            termsChecked: !this.state.termsChecked
        });
    }

    removeAttachment() {
        this.setState({
            fileName: '',
            fileData: '',
            accepted: [],
            rejected: [],
        });
    }

    triggerFormSend() {
        // Set states from fields that use formsy.
        const formsyValues = this.refs.form.getModel();
        let school = 'Karelia-ammattikorkeakoulu';
        if (this.state.school === 'uef') {
            school = 'Itä-Suomen yliopisto';
        }
        else if (this.state.school === 'riveria') {
            school = 'Pohjois-Karjalan koulutuskuntayhtymä Riveria';
        }
        let role = 'Opiskelija';
        if (this.state.role === 'staff') {
            role = 'Henkilökunta';
        }
        else if (this.state.role === 'alumni') {
            role = 'Alumni';
        }
        const schoolAndRole = school + '|' + role + '|' + formsyValues.organisationText.replace('|', '&#124;');
        this.setState({
                name: formsyValues.name,
                email: formsyValues.email,
                emailVerification: formsyValues.emailVerification,
                phone: formsyValues.phone,
                organisation: schoolAndRole,
                title: formsyValues.title,
                heardFrom: formsyValues.heardFrom,
            },
            // Callback to compileFormData once setState is done for.
            this.compileFormData
        );
        // Reset the reCaptcha < Here to avoid timeouts if modal is not closed.
        this.recaptchaInstance.reset();
    }

    // Send form
    compileFormData() {
        const { dispatch } = this.props;
        const formData = this.state;
        // Send form data
        dispatch(addDraft(formData));
    }

    reInitTinyMCE() {
        // eslint-disable-next-line no-undef
        tinymce.remove();
        // eslint-disable-next-line no-undef
        tinymce.init({
            name: 'decription',
            id: 'decription',
            selector: 'textarea',
            language_url: this.state.tinyMCELang,
            language: this.state.tinyMCELocale,
            plugins: 'autolink link autoresize lists fullscreen image',
            toolbar1: 'bold italic underline forecolor fontsizeselect link image',
            toolbar2: 'undo redo alignleft aligncenter alignright alignjustify outdent indent fullscreen',
            fontsize_formats: '11pt 12pt 13pt 15pt',
            textcolor_cols: '5',
            autoresize_bottom_margin: 80,
            default_link_target: '_blank',
            content_style: 'img {max-width: 320px; max-height: 320px; border: solid 2px black; margin: 10px;}',
            image_description: false,
            image_dimensions: false,
            menubar: false,
            statusbar: false,
            branding: true,
            mobile: {
                theme: 'silver'
            },
        });
        // eslint-disable-next-line no-undef
        tinymce.EditorManager.get('description').on('keyUp', e => {
            this.descriptionEdited(e);
        });
        // eslint-disable-next-line no-undef
        tinymce.EditorManager.get('description').on('change', e => {
            this.descriptionEdited(e);
        });
    }

    toggleTerms() {
        // If opening, show details.
        this.setState({
            showTermsModal: !this.state.showTermsModal,
        });
        // Scroll to the bottom of the page.
        window.scrollTo(0, document.body.scrollHeight);
    }

    toggle() {
        // If opening, show details.
        if (!this.state.showSentFormModal) {
            this.setState({
                showSentFormModal: !this.state.showSentFormModal,
            });
        }
        // If closing, reset form.
        else {
            this.setState({
                showSentFormModal: !this.state.showSentFormModal,
                competition: '',
                selectedCompetitionText: '',
                competitionValid: false,
                name: '',
                email: '',
                emailVerification: '',
                phone: '',
                roleText: '',
                organisation: '',
                members: '',
                membersValid: false,
                title: '',
                description: '',
                descriptionPristine: true,
                descriptionValid: false,
                heardFrom: '',
                termsChecked: false,
                submitBtnEnable: true,
                alertVisible: false,
                fileName: '',
                fileData: '',
                accepted: [],
                rejected: [],
            });
            // Reset tinyMCE and formsy fields.
            // TO DO: Reset formatting settings.
            tinymce.EditorManager.get('description').setContent(''); // eslint-disable-line
            this.refs.form.reset();
            // Scroll to the top of the page.
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }

    formsyValidationErrors() {
        this.setState({ formsyValidationIsValid: false });
    }

    formsyValidationOK() {
        this.setState({ formsyValidationIsValid: true });
    }

    fetchRunningCompetitions() {
        const { dispatch } = this.props;
        dispatch(fetchRunningCompetitions());
    }

    competitionEdited(e) {
        // Get selected text value for showing it to the user...
        const selectElement = document.getElementById('draftCompetition');
        const selectedIndex = selectElement.selectedIndex;
        this.setState({
            selectedCompetitionText: selectElement.options[selectedIndex].text,
        });
        if (e.target.value === 'default')
        {
            this.setState({
                competition: e.target.value,
                competitionValid: false,
            });
        }
        else
        {
            this.setState({
                competition: e.target.value,
                competitionValid: true,
            });
        }
    }

    senderSchoolChanged(e) {
        this.setState({
            school: e.target.value
        });
    }

    senderRoleChanged(e) {
        if (e.target.value === 'staff') {
            this.setState({
                isStudent: false,
                role: 'staff',
                roleTextIcon: <i className="fas fa-chalkboard-teacher label-icon" />
            });
        }
        else {
            let setRole = 'student';
            let setRoleText = <i className="fas fa-school label-icon" />;
            if (e.target.value === 'alumni') {
               setRole = 'alumni';
               setRoleText = <i className="fas fa-graduation-cap label-icon" />;
            }
            this.setState({
                isStudent: true,
                role: setRole,
                roleTextIcon: setRoleText
            });
        }
    }

    membersEdited(e) {
        if (e.target.value === 'default')
        {
            this.setState({
                members: e.target.value,
                membersValid: false,
            });
        }
        else
        {
            this.setState({
                members: e.target.value,
                membersValid: true,
            });
        }
    }

    descriptionEdited() {
        // eslint-disable-next-line no-undef
        let newContent = '';
        try {
            newContent = tinymce.EditorManager.get('description').getContent();
        }
        catch (e) {
            console.log("tinymce.EditorManager.get('description').getContent() failed: " + e);
        }
        // New row for example adds two characters, lets make the limit 15 to make sure we get at least 10 min.
        this.setState({
            description: newContent,
            descriptionPristine: false,
        });
        if (newContent.length > 14)
        {
            this.setState({ descriptionValid: true });
        }
        else {
            // If we set the state to match invalidInput the error text will be shown in the UI.
            this.setState({ descriptionValid: false, });
        }
    }

    competitionListItem(props) {
        const nameFi = props.competition.nameFi;
        const nameEn = props.competition.nameEn;
        return (
            <option value={props.competition._id}>{this.state.captchaLocale === 'fi' ? nameFi : nameEn}</option>
        );
    }

    render() {
        const dropzoneActiveStyle = {
            border: '2px solid #ced4da',
        };
        const langUrl = this.state.tinyMCELang;
        const langLocale = this.state.tinyMCELocale;
        const reCaptchaEn = '<p className="google-captcha-text">This site is protected by reCAPTCHA and the Google ' +
            '<a target="_blank" href="https://policies.google.com/privacy"> Privacy Policy</a> and ' +
            '<a target="_blank" href="https://policies.google.com/terms"> Terms of Service</a> apply.' +
            '</p>';
        const reCaptchaFi = '<p className="google-captcha-text">Suojattu reCAPTCHA-palvelun avulla.<br>Googlen' +
            '<a target="_blank" href="https://www.google.com/intl/fi/policies/privacy/"> tietosuoja</a> ja ' +
            '<a target="_blank" href="https://www.google.com/intl/fi/policies/terms/"> ehdot</a> pätevät.' +
            '</p>';
        return (
            <div className="form jumbotron padded-bottom">
                <Formsy onValid={this.formsyValidationOK} ref="form" onInvalid={this.formsyValidationErrors}>
                    <section className="form-group-card idea-card">
                        <h2 className="card-title idea-title"><span><i className="fas fa-file-alt label-icon" /><FormattedMessage id="draftApplication" /></span></h2>
                        <FormGroup>
                            <Label for="draftCompetition"><i className="fas fa-bullseye label-icon" /><FormattedMessage id="draftCompetitionTitle" /> *</Label>
                            <Input type="select" name="draftCompetition" id="draftCompetition" onChange={this.competitionEdited}
                                   value={this.state.competition}>
                                <option value="default">{this.props.intl.messages.draftCompetitionDefault}</option>
                                {
                                    this.props.competitions.competitionList.map(competition => (
                                        <this.competitionListItem
                                            competition={competition}
                                            key={competition._id}
                                        />
                                    ))
                                }
                            </Input>
                            {!this.state.competitionValid ? <span className="validation-error select-validation">
                        <FormattedMessage id="draftCompetitionInvalid" /> </span> : null }
                        </FormGroup>
                        <FormsyInput
                            name="title"
                            icon={<i className="fas fa-pencil-alt label-icon" />}
                            message={<FormattedMessage id="draftTitle" />}
                            validations={{
                                minLength: 5,
                                maxLength: 80
                            }}
                            validationErrors={{
                                minLength: <FormattedMessage id="draftTitleInvalid" />,
                                maxLength: <FormattedMessage id="inputTooLong" />
                            }}
                            required
                        />
                        <FormGroup className="tinymce-editor">
                            <Label for="description"><i className="fas fa-lightbulb label-icon" /><FormattedMessage id="draftDescription" /> *</Label>
                            <Editor
                                apiKey="gfe4wvrlk2ftcypnv28g0apfl37t4v1r4fbmyi25d68o9tqv"
                                id="description"
                                content={this.state.description}
                                init={{
                                    selector: 'textarea',
                                    language_url: langUrl,
                                    language: langLocale,
                                    plugins: 'autolink link autoresize lists fullscreen image',
                                    toolbar1: 'bold italic underline forecolor fontsizeselect link image',
                                    toolbar2: 'undo redo alignleft aligncenter alignright alignjustify outdent indent fullscreen',
                                    fontsize_formats: '11pt 12pt 13pt 15pt',
                                    textcolor_cols: '5',
                                    autoresize_bottom_margin: 80,
                                    default_link_target: '_blank',
                                    content_style: 'img {max-width: 320px; max-height: 320px; border: solid 2px black; margin: 10px;}',
                                    image_description: false,
                                    image_dimensions: false,
                                    menubar: false,
                                    statusbar: false,
                                    branding: true,
                                    mobile: {
                                        theme: 'silver'
                                    }
                                }}
                                onKeyUp={this.descriptionEdited}
                                onChange={this.descriptionEdited}
                            />
                            {!this.state.descriptionValid && !this.state.descriptionPristine ? <p className="validation-error">
                                <FormattedMessage id="draftDescriptionInvalid" /></p> : null}
                        </FormGroup>
                        <FormGroup>
                            <Label for="draftMembers"><i className="fas fa-users label-icon" /><FormattedMessage id="draftMembers" /> *</Label>
                            <Input type="select" name="draftMembers" id="draftMembers" onChange={this.membersEdited} value={this.state.members}>
                                <option value="default">{this.props.intl.messages.draftMembersDefault}</option>
                                <option value="2">2 {this.props.intl.messages.persons}</option>
                                <option value="3">3 {this.props.intl.messages.persons}</option>
                                <option value="4">4 {this.props.intl.messages.persons}</option>
                                <option value="5">5 {this.props.intl.messages.persons}</option>
                                <option value="6">6 {this.props.intl.messages.persons}</option>
                                <option value="7">7 {this.props.intl.messages.persons}</option>
                                <option value="8">8 {this.props.intl.messages.persons}</option>
                                <option value="9">9 {this.props.intl.messages.persons}</option>
                                <option value="10">10 {this.props.intl.messages.persons}</option>
                                <option value="+10">+10 {this.props.intl.messages.persons}</option>
                            </Input>
                            {!this.state.membersValid ? <span className="validation-error select-validation">
                            <FormattedMessage id="draftMembersInvalid" /> </span> : null}
                        </FormGroup>
                        <FormGroup>
                            {/* Dropzone: Max 10 MB (10485760 bytes), single pdf file. Since we convert file to base 64,
                        the size may increases by up to 30%, max single document size for MongoDB is 16 MB. */}
                            <section>
                                <Label for="dropzone"><i className="fas fa-paperclip label-icon" /><FormattedMessage id="draftPdfLabel" /> (max 10 mb)</Label>
                                <Dropzone
                                    className="dropzone"
                                    id="dropzone"
                                    disabled={!this.state.submitBtnEnable}
                                    accept="application/pdf"
                                    multiple={false}
                                    maxSize={10485760}
                                    activeStyle={dropzoneActiveStyle}
                                    onDrop={(accepted, rejected) => {
                                        if (accepted.length > 0 || rejected.length > 0) {
                                            this.setState({ accepted, rejected });
                                        }
                                        /* If the file is accepted, convert it to base64 */
                                        if (accepted.length > 0) {
                                            // Disable submit button while uploading file
                                            this.setState({
                                                submitBtnEnable: false,
                                                alertVisible: false,
                                            });
                                            const promise = new Promise((resolve, reject) => {
                                                document.body.style.cursor = 'wait';
                                                const reader = new FileReader();
                                                reader.readAsDataURL(accepted[0]);
                                                reader.onload = () => {
                                                    if (reader.result) {
                                                        resolve(reader.result);
                                                    } else {
                                                        reject(Error('Failed converting to base64'));
                                                    }
                                                };
                                            });
                                            promise.then((result) => {
                                                const promise2 = new Promise((resolve) => {
                                                    const base64String = result.substr(28);
                                                    resolve(this.setState({
                                                        fileName: accepted[0].name,
                                                        fileData: base64String
                                                    }));
                                                });
                                                promise2.then(() => {
                                                    document.body.style.cursor = 'default';
                                                    this.setState({
                                                        submitBtnEnable: true
                                                    });
                                                });
                                                // TO DO: result includes: data:application/pdf;base64, < this is not part of Base64
                                                // We remove it with JS, would it be possible to make it directly to result?
                                            }, (err) => {
                                                console.log(err);
                                                document.body.style.cursor = 'default';
                                            });
                                        } else if (rejected.length > 0) {
                                            this.setState({
                                                alertVisible: true,
                                                submitBtnEnable: true,
                                                alertText: <FormattedMessage id="draftPdfRejectedAlert"/>
                                            });
                                        }
                                    }
                                    }
                                >
                                    <p><FormattedMessage id="draftPdfInfo" /></p>
                                    <p><FormattedMessage id="draftPdfExtension" /></p>
                                </Dropzone>
                                <aside style={{ marginTop: 1.3 + 'em', marginBottom: 1.3 + 'em' }}>
                                    {this.state.accepted.length > 0 ?
                                        <p style={{ fontSize: '120' + '%', color: ' green' }}>{this.state.accepted[0].name}&nbsp;&nbsp;|
                                            &nbsp;&nbsp;{(this.state.accepted[0].size / (1000000)).toFixed(2)} MB
                                            &nbsp;&nbsp;<Button color="danger" disabled={!this.state.submitBtnEnable}
                                                                onClick={this.removeAttachment}>X</Button></p> : null}
                                    {this.state.rejected.length > 0 ?
                                        <p style={{ fontSize: '120' + '%', color: ' #e50d0d' }}><FormattedMessage id="draftPdfRejected"/>:
                                            &nbsp;{this.state.rejected[0].name}&nbsp;&nbsp;|
                                            &nbsp;&nbsp;&nbsp;{(this.state.rejected[0].size / (1000000)).toFixed(2)} MB</p> : null}
                                </aside>
                                <Alert color="danger" isOpen={this.state.alertVisible} toggle={this.onDismissAlert}>
                                    {this.state.alertText}
                                </Alert>
                            </section>
                        </FormGroup>
                    </section>
                    <section className="form-group-card contact-card">
                        <h2 className="card-title contact-title"><span><i className="fas fa-id-card label-icon" /><FormattedMessage id="draftContactDetails" /></span></h2>
                        <p className="contact-info"><FormattedMessage id="draftContactInfoText" /></p>
                        <div className="row contact-row">
                            <div className="col-md-6 school-left-col">
                                <FormsyInput
                                name="name"
                                icon={<i className="fas fa-user-tag label-icon" />}
                                message={<FormattedMessage id="draftSenderName" />}
                                validations={{
                                    matchRegexp: /[\S]+[\s]+[\S]/,
                                    maxLength: 80
                                }}
                                validationErrors={{
                                    matchRegexp: <FormattedMessage id="draftSenderNameInvalid" />,
                                    maxLength: <FormattedMessage id="inputTooLong" />
                                }}
                                required
                            />
                            <FormsyInput
                                name="email"
                                icon={<i className="fas fa-envelope label-icon" />}
                                message={<FormattedMessage id="draftSenderEmail" />}
                                validations={{
                                    isEmail: true,
                                    maxLength: 80
                                }}
                                validationErrors={{
                                    isEmail: <FormattedMessage id="draftSenderEmailInvalid" />,
                                    maxLength: <FormattedMessage id="inputTooLong" />
                                }}
                                required
                            />
                            <FormsyInput
                                name="emailVerification"
                                icon={<i className="fas fa-envelope-square label-icon" />}
                                message={<FormattedMessage id="draftSenderConfirmEmail" />}
                                validations="equalsField:email"
                                validationError={<FormattedMessage id="draftSenderConfirmEmailInvalid" />}
                                required
                            />
                            <FormsyInput
                                name="phone"
                                icon={<i className="fas fa-phone-square label-icon" />}
                                message={<FormattedMessage id="draftSenderPhone" />}
                                validations={{
                                    matchRegexp: /\d\d\d/,
                                    minLength: 6,
                                    maxLength: 80
                                }}
                                validationErrors={{
                                    matchRegexp: <FormattedMessage id="draftSenderPhoneInvalid" />,
                                    minLength: <FormattedMessage id="draftSenderPhoneInvalid" />,
                                    maxLength: <FormattedMessage id="inputTooLong" />
                                }}
                            />
                            </div>
                            <div className="col-md-6 school-right-col">
                            <FormGroup className="school-selector-group" tag="fieldset">
                                <div className="school-selector row">
                                    <Label className="school-title col-12"><span><i className="fas fa-university label-icon" /><FormattedMessage id="draftSenderSchool" /></span></Label>
                                    <div className="school-col school-karelia col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="school" id="karelia" value="karelia" defaultChecked
                                               onChange={this.senderSchoolChanged} />{' '}
                                        <Label check for="karelia" id="schoolKarelia"><img className="selector-karelia" src={kareliaLogo} alt="Karelia logo" /></Label>
                                        <UncontrolledTooltip placement="bottom-start" hideArrow target="schoolKarelia">
                                            <FormattedMessage id="karelia" />
                                        </UncontrolledTooltip>
                                    </div>
                                    <div className="school-col school-uef col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="school" id="uef" value="uef" onChange={this.senderSchoolChanged} />{' '}
                                        <Label check for="uef" id="schoolUef"><img className="selector-uef" src={uefLogo} alt="UEF logo" /></Label>
                                        <UncontrolledTooltip placement="bottom-start" hideArrow target="schoolUef">
                                            <FormattedMessage id="uef" />
                                        </UncontrolledTooltip>
                                    </div>
                                    <div className="school-col school-riveria col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="school" id="riveria" value="riveria" onChange={this.senderSchoolChanged} />{' '}
                                        <Label check for="riveria" id="schoolRiveria"><img className="selector-riveria" src={riveriaLogo} alt="Riveria logo" /></Label>
                                        <UncontrolledTooltip placement="bottom-start" hideArrow target="schoolRiveria">
                                            <FormattedMessage id="riveria" />
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className="role-selector-group" tag="fieldset" inline>
                                <div className="school-selector row">
                                    <div className="school-col school-karelia col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="senderRole" id="student" value="student" defaultChecked
                                               onChange={this.senderRoleChanged} />{' '}
                                        <Label check for="student"> <FormattedMessage id="draftSenderStudent" /></Label>
                                    </div>
                                    <div className="school-col school-uef col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="senderRole" id="staff" value="staff" onChange={this.senderRoleChanged} />{' '}
                                        <Label check for="staff"> <FormattedMessage id="draftSenderStaff" /></Label>
                                    </div>
                                    <div className="school-col school-riveria col-xl-4 col-lg-12 col-md-12 col-sm-4 col-s-12">
                                        <Input type="radio" name="senderRole" id="alumni" value="alumni" onChange={this.senderRoleChanged} />{' '}
                                        <Label check for="alumni"> <FormattedMessage id="draftSenderAlumni" /></Label>
                                    </div>
                                </div>
                            </FormGroup>
                            <div className="school-selector col-12 organisation-text-group">
                                <FormsyInput
                                    className="organisation-text"
                                    name="organisationText"
                                    icon={this.state.roleTextIcon}
                                    message={this.state.isStudent ? <FormattedMessage id="draftSenderStudentOrAlumni" /> :
                                        <FormattedMessage id="draftSenderStaffPosition" />}
                                    validations={{
                                        minLength: 5,
                                        maxLength: 90
                                    }}
                                    validationErrors={{
                                        minLength: <FormattedMessage id="draftSenderOrganisationInvalid" />,
                                        maxLength: <FormattedMessage id="inputTooLong" />
                                    }}
                                    required
                                />
                            </div>
                            </div>
                            </div>
                    </section>
                    <section className="form-group-card finalize-card">
                        <h2 className="card-title finalize-title"><span><i className="fas fa-file-signature label-icon" /><FormattedMessage id="draftFinalize" /></span></h2>
                        <div className="finalize-content row">
                        <div className="heard-from-terms-container col-md-6">
                            <FormsyInput
                                className="heard-from"
                                name="heardFrom"
                                icon={<i className="ie-hidden fas fa-question-circle label-icon" />}
                                message={<FormattedMessage id="draftHeardFrom" />}
                                validations={{
                                    minLength: 4,
                                    maxLength: 90
                                }}
                                validationErrors={{
                                    minLength: <FormattedMessage id="draftHeardFromInvalid" />,
                                    maxLength: <FormattedMessage id="inputTooLong" />
                                }}
                                required
                            />
                                <div className="terms-row">
                                <FormGroup check>
                                <Input type="checkbox" id="acceptTerms" className="accept-terms-checkbox" checked={this.state.termsChecked}
                                       onChange={this.acceptTerms.bind(this)} />{' '}
                                <div style={{ marginLeft: 40 + 'px' }}>
                                    <strong><Label for="acceptTerms" className="terms-label">
                                        <a href="#" target="_self" onClick={this.toggleTerms}><FormattedMessage id="draftTerms" /></a></Label></strong>
                                </div>
                                </FormGroup>
                            </div>
                        </div>
                        <Alert color="danger" isOpen={this.state.alertVisible} toggle={this.onDismissAlert}>
                            {this.state.alertText}
                        </Alert>
                        <div className="submit-row col-md-6">
                        {this.state.submitBtnEnable && this.state.termsChecked &&
                        this.state.formsyValidationIsValid && this.state.membersValid && this.state.descriptionValid ?
                            <Button color="success" size="sm" className="send-btn" onClick={this.onSubmit}><FormattedMessage id="submit" />
                            </Button> : <Button color="success" size="sm" className="send-btn" disabled><FormattedMessage id="submit" /></Button>}
                            {this.state.termsChecked === true &&
                            this.state.formsyValidationIsValid === true && this.state.competitionValid && this.state.membersValid && this.state.descriptionValid ?
                                '' : <p className="form-incomplete"><i className="fas fa-exclamation-circle label-icon" /><FormattedMessage id="formIncomplete" /></p>}
                        </div>
                            <Recaptcha
                                key={this.state.captchaLocale}
                                ref={ref => this.recaptchaInstance = ref}
                                sitekey="6LfLGmkUAAAAAOU4B_cRO3L1Jrm8TJ9gXCSiLks7"
                                render="explicit"
                                size="invisible"
                                badge="explicit"
                                hl={this.state.captchaLocale}
                                verifyCallback={this.onResolved}
                                onloadCallback={this.onLoad}
                            />
                        </div>
                    </section>
                </Formsy>
                <Modal isOpen={this.state.showSentFormModal} toggle={this.toggle} className="modal-lg always-lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} className="modalHeader"><FormattedMessage id="formSubmitted" /></ModalHeader>
                    <SentDraftDetails form={this.state} />
                    <ModalFooter className="modalFooter">
                        <Button color="primary" onClick={this.toggle}><FormattedMessage id="close" /></Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showTermsModal} toggle={this.toggleTerms} className="modal-lg" backdrop="static">
                    <ModalHeader toggle={this.toggleTerms} className="modalHeader"><FormattedMessage id="termsModalTitle" /></ModalHeader>
                    {this.state.tinyMCELocale === 'fi' ?
                        <TermsModal terms={this.props.termsFi} captchaText={reCaptchaFi} /> : <TermsModal terms={this.props.termsEn} captchaText={reCaptchaEn} />}
                    <ModalFooter className="modalFooter">
                        <Button color="primary" onClick={this.toggleTerms}><FormattedMessage id="close" /></Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

DraftCreateWidget.contextTypes = {
    router: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        competitions: state.competitions,
    };
}

export default connect(mapStateToProps)(DraftCreateWidget);
