import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { logUserIn, increaseLoginAttempts, checkSession } from '../../../../actions/AuthenticationActions';

export class LoginPageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			showLoginFailed: false,
			loginFailCount: 0,
			waitTime: 0,
			errorMessage: 'Annetulla käyttäjätunnus/salasana yhdistelmällä ei löytynyt tunnuksia.'
		};
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.checkIfEnter = this.checkIfEnter.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount() {
		// Check session, if user is logged in componentWillReceiveProps will redirect the user.
		const { dispatch } = this.props;
		dispatch(checkSession());
		document.title = 'Kirjautuminen | Draft';
		// https://jsfiddle.net/alvaroAV/svvz7tkn/
		/* Create an alert to show if the browser is IE */
		if (navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1) {
			alert('Internet Explorer selainta ei tueta. Ole hyvä ja käytä Chromea, Firefoxia, Edgeä, Operaa tai Safaria.');
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Check if logging in status has changed.
		if (nextProps.authentication.isLoggedIn) {
			clearInterval(this.interval);
			return (
				<Redirect to="/hakemukset" />
			);
		}
        // If the status is changed to false and we are not logged in, alert the user of failed login attempt.
		if (nextProps.authentication.isLoggingIn !== this.props.authentication.isLoggingIn) {
            const element = document.getElementById('loginPage');
            element.classList.remove('animated');
            element.classList.remove('shake');
			if (!nextProps.authentication.isLoggingIn && !nextProps.authentication.isLoggedIn) {
				clearInterval(this.interval);
				this.setState({ showLoginFailed: true });
                element.classList.add('animated');
                element.classList.add('shake');
				this.setState({
					errorMessage: 'Annetulla käyttäjätunnus/salasana yhdistelmällä ei löytynyt tunnuksia.'
				});
				const { dispatch } = this.props;
				dispatch(increaseLoginAttempts(this.state));
			}
		}
		if (nextProps.authentication.loginWaitTime > 0) {
			clearInterval(this.interval);
			this.setState({
				loginFailCount: nextProps.authentication.loginAttempts,
				errorMessage: 'Tilille on yritetty kirjautua ' + nextProps.authentication.loginAttempts + ' kertaa.',
				waitTime: nextProps.authentication.loginWaitTime + 1
			});
			this.interval = setInterval(() => {
				let newWaitTime = this.state.waitTime - 1;
				if (newWaitTime < 0) {
					newWaitTime = 0;
				}
				this.setState({ waitTime: newWaitTime });
			}, 1000);
		}
	}

	UNSAFE_componentWillUnmount() {
		clearInterval(this.interval);
	}

	handleEmailChange(e) {
		this.setState({ email: e.target.value });
	}
	handlePasswordChange(e) {
		this.setState({ password: e.target.value });
	}

	// Handle submission once all form data is valid
	handleFormSubmit() {
		const formData = this.state;
		const { dispatch } = this.props;
		dispatch(logUserIn(formData));
	}

	// catch enter clicks
	checkIfEnter(target) {
		if (target.charCode === 13) {
			this.handleFormSubmit();
		}
	}

	render() {
		if (this.props.authentication.isLoggedIn) {
			return (
				<Redirect to="/hakemukset" />
			);
		}
		let loginFailed = '';
		if (this.state.showLoginFailed)
		{
			loginFailed = this.state.errorMessage;
			this.state.waitTime > 0 ? loginFailed = loginFailed + ' Yritä uudestaan  ' + this.state.waitTime + ' sekunnin kuluttua.' : '';
		}
		return (
			<div className="login-page" id="loginPage">
				<div className="form login-container">
					<p>Tervetuloa Draft -hakulomakejärjestelmään!<br />Kirjaudu sisään käyttäjätunnuksillasi.</p>
					<br />
					<Form>
						<FormGroup>
							<Label for="exampleEmail">Sähköpostiosoite</Label>
							<Input type="email" required name="email" placeholder="Sähköposti"
							       onKeyPress={this.checkIfEnter} onChange={this.handleEmailChange} />
						</FormGroup>
						<FormGroup>
							<Label for="examplePassword">Salasana</Label>
							<Input type="password" required name="password" placeholder="Salasana"
							       onKeyPress={this.checkIfEnter} onChange={this.handlePasswordChange} />
						</FormGroup>
						<Button className="button" href="#" target="_self" disabled={this.state.waitTime > 0} onClick={this.handleFormSubmit}>Kirjaudu</Button>
					</Form>
					<span className="message"><Link to="/hakemukset/salasanan-palautus">Haluatko muuttaa salasanasi tai unohditko sen?
              </Link></span>
					<br /><br />
					<h5 style={{ color: 'red' }}>{loginFailed}</h5>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		authentication: state.authentication,
	};
}

export default connect(mapStateToProps)(LoginPageContainer);
