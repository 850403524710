// Default export from a module
import React from 'react';

// Individual method exports from a module
import { setConfig, AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import 'bootstrap/dist/css/bootstrap.min.css';
import configureStore from './reducers/store';
import Template from './components/Template';
import './shared/loader.scss';
import './shared/cssStyles.scss';
import './shared/fonts/Constantia.scss';

const Store = configureStore();
setConfig({
  showReactDomPatchNotification: false
});

const renderApp = (Component) => {
  render(
    <AppContainer>
      <Provider store={Store}>
        <div>
          <Component />
        </div>
      </Provider>
    </AppContainer>,
    document.querySelector('#react-app'),
  );
};

renderApp(Template);

if (module && module.hot) {
  module.hot.accept('./components/Template', () => {
    renderApp(Template);
  });
}
