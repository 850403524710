import { enabledLanguages, localizationData } from '../components/enlist/intl/Setup';
import { SWITCH_LANGUAGE } from '../actions/IntlActions';

let initLocale = 'fi';

// If url contains /en, change the default locale to en. More in DraftCreateWidget
if (window.location.href.indexOf('/en') > -1) {
    initLocale = 'en';
}

const initialState = {
  locale: initLocale,
  enabledLanguages,
  ...(localizationData[initLocale] || {}),
};

const IntlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE: {
      const { ...actionWithoutType } = action; // eslint-disable-line
      return { ...state, ...actionWithoutType };
    }

    default:
      return state;
  }
};

export default IntlReducer;
