// Initial State
/** ***********************
 * addCompetitionStatus:
 * 0 = Not sending.
 * 1 = Sending in progress.
 * 2 = Sending failed.
 * 3 = Sending success.
 *
 * competitionUpdateStatus:
 * 0 = Default (not updating)
 * 1 = Updating.
 * 2 = Update failed
 * 3 = Update success.
 *
 * competitionToggleStatus:
 * 0 = Default (not toggling)
 * 1 = In progress.
 * 2 = Failed
 * 3 = Success.
 *
 * competitionAddAccessStatus:
 * 0 = Default
 * 1 = In progress.
 * 2 = Failed
 * 3 = Success.
 *
 * competitionRemoveAccessStatus:
 * 0 = Default
 * 1 = In progress.
 * 2 = Failed
 * 3 = Success.
 *
 * deleteCompetitionStatus:
 * 0 = Not deleting.
 * 1 = Deleting in progress.
 * 2 = Delete failed.
 * 3 = Delete success.
 */
const initialState = {
    competitionList: [],
    competitionListWithAccess: [],
    isFetchingCompetitions: false,
    competitionUpdateStatus: 0,
    competitionToggleStatus: 0,
    competitionAddAccessStatus: 0,
    addCompetitionStatus: 0,
    deleteCompetitionStatus: 0,

};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'COMPETITION_ADD_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.addCompetitionStatus = 1;
            newState.competitionUpdateStatus = 0;
            newState.competitionAddAccessStatus = 0;
            newState.competitionRemoveAccessStatus = 0;
            newState.competitionToggleStatus = 0;
            newState.deleteCompetitionStatus = 0;
            return newState;
        }
        case 'COMPETITION_ADD_FAILURE': {
            const newState = Object.assign({}, state);
            newState.addCompetitionStatus = 2;
            return newState;
        }
        case 'COMPETITION_ADD_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.addCompetitionStatus = 3;
            return newState;
        }
        case 'COMPETITIONS_FETCH_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.isFetchingCompetitions = true;
            return newState;
        }
        case 'COMPETITIONS_FETCH_FAILURE': {
            return Object.assign({}, state);
        }
        case 'COMPETITIONS_FETCH_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionList = action.json;
            newState.isFetchingCompetitions = false;
            return newState;
        }
        case 'COMPETITIONS_WITH_ACCESS_FETCH_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.isFetchingCompetitions = true;
            return newState;
        }
        case 'COMPETITIONS_WITH_ACCESS_FETCH_FAILURE': {
            return Object.assign({}, state);
        }
        case 'COMPETITIONS_WITH_ACCESS_FETCH_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionListWithAccess = action.json;
            newState.isFetchingCompetitions = false;
            return newState;
        }
        case 'COMPETITION_UPDATE_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.competitionUpdateStatus = 1;
            newState.competitionToggleStatus = 0;
            newState.competitionAddAccessStatus = 0;
            newState.competitionRemoveAccessStatus = 0;
            newState.addCompetitionStatus = 0;
            newState.deleteCompetitionStatus = 0;
            return newState;
        }
        case 'COMPETITION_UPDATE_FAILURE': {
            const newState = Object.assign({}, state);
            newState.competitionUpdateStatus = 2;
            return newState;
        }
        case 'COMPETITION_UPDATE_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionUpdateStatus = 3;
            return newState;
        }
        case 'COMPETITION_TOGGLE_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.competitionToggleStatus = 1;
            newState.competitionUpdateStatus = 0;
            newState.competitionAddAccessStatus = 0;
            newState.competitionRemoveAccessStatus = 0;
            newState.addCompetitionStatus = 0;
            newState.deleteCompetitionStatus = 0;
            return newState;
        }
        case 'COMPETITION_TOGGLE__FAILURE': {
            const newState = Object.assign({}, state);
            newState.competitionToggleStatus = 2;
            return newState;
        }
        case 'COMPETITION_TOGGLE_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionToggleStatus = 3;
            return newState;
        }
        case 'COMPETITION_ADD_ACCESS_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.competitionAddAccessStatus = 1;
            newState.competitionRemoveAccessStatus = 0;
            newState.competitionToggleStatus = 0;
            newState.competitionUpdateStatus = 0;
            newState.addCompetitionStatus = 0;
            newState.deleteCompetitionStatus = 0;
            return newState;
        }
        case 'COMPETITION_ADD_ACCESS_FAILURE': {
            const newState = Object.assign({}, state);
            newState.competitionAddAccessStatus = 2;
            return newState;
        }
        case 'COMPETITION_ADD_ACCESS_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionAddAccessStatus = 3;
            return newState;
        }
        case 'COMPETITION_REMOVE_ACCESS_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.competitionRemoveAccessStatus = 1;
            newState.competitionAddAccessStatus = 0;
            newState.competitionToggleStatus = 0;
            newState.competitionUpdateStatus = 0;
            newState.addCompetitionStatus = 0;
            newState.deleteCompetitionStatus = 0;
            return newState;
        }
        case 'COMPETITION_REMOVE_ACCESS_FAILURE': {
            const newState = Object.assign({}, state);
            newState.competitionRemoveAccessStatus = 2;
            return newState;
        }
        case 'COMPETITION_REMOVE_ACCESS_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.competitionRemoveAccessStatus = 3;
            return newState;
        }
        case 'DELETE_COMPETITION_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.deleteCompetitionStatus = 1;
            return newState;
        }
        case 'DELETE_COMPETITION_FAILURE': {
            const newState = Object.assign({}, state);
            newState.deleteCompetitionStatus = 2;
            return newState;
        }
        case 'DELETE_COMPETITION_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.deleteCompetitionStatus = 3;
            return newState;
        }
        default: {
            return state;
        }
    }
}
