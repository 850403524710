import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Formsy from 'formsy-react';
import { createHash } from '../../../../actions/PassResetActions';
import FormsyInput from '../../../../shared/formsy';

export class ResetPasswordPageContainer extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          email: '',
          canSubmit: false,
      };
    // bound functions
    this.clearPasswordReset = this.clearPasswordReset.bind(this);
    this.checkIfEnter = this.checkIfEnter.bind(this);
    this.triggerFormSend = this.triggerFormSend.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
  }

    componentDidMount() {
        document.title = 'Salasanan palautus | Draft';
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Shake if failure.
        if (nextProps.passwordManagement.isPasswordReset !== this.props.passwordManagement.isPasswordReset) {
            const element = document.getElementById('loginPage');
            element.classList.remove('animated');
            element.classList.remove('shake');
            if (nextProps.passwordManagement.isPasswordReset === 2) {
                this.setState({ showLoginFailed: true });
                element.classList.add('animated');
                element.classList.add('shake');
            }
        }
    }

    // Reload the page thus re-enabling the form.
    clearPasswordReset() {
        window.location.reload(false);
    }

    triggerFormSend() {
        // Set states from fields that use formsy.
        const formsyValues = this.refs.form.getModel();
        // For some reason, the button is not disabled when the page loads and thus sending empty form is possible.
        if (formsyValues.email)
        {
            this.setState({
                    email: formsyValues.email,
                },
                // Callback to handleValidSubmit once setState is done for.
                this.handleValidSubmit
            );
        }
        else
        {
            alert('Sähköpostikenttä on tyhjä.');
        }
    }

    // Handle submission once all form data is valid
    handleValidSubmit() {
        const formData = this.state;
        const { dispatch } = this.props;
        dispatch(createHash(formData));
    }

    // catch enter clicks
    checkIfEnter(target) {
      // console.log("check enter...");
        if (target.charCode === 13) {
            this.handleValidSubmit();
        }
    }

    disableButton() {
        this.setState({ canSubmit: false });
    }

    enableButton() {
        this.setState({ canSubmit: true });
    }

  render() {
    // To do: How to add checkEnter to Formsy?
    let inputSection = (<Formsy ref="form" onValid={this.enableButton} onInvalid={this.disableButton}>
            <FormsyInput
                name="email"
                message="Sähköpostiosoite"
                validations="isEmail"
                validationError="Sähköpostiosoite on virheellinen."
                onKeyPress={this.checkIfEnter}
            />
            <Button className="button" href="#" target="_self"
                    disabled={!this.state.canSubmit}
                    onClick={this.triggerFormSend}
            >Lähetä palautuslinkki</Button>
            </Formsy>);
    let errorSection = '';
    if (this.props.passwordManagement.isPasswordReset === 2) {
      errorSection = (<div>
          <p style={{ color: 'red', marginTop: '10px' }}>
              Salasanan palautus epäonnistui, varmista että sähköpostiosoite on oikein ja yritä uudestaan.
          </p>
      </div>);
    }
    if (this.props.passwordManagement.isPasswordReset === 3) {
      inputSection = (<div>
                  <p>
                      Antamaasi sähköpostiosoitteeseen lähetettiin juuri linkki salasanan muuttamista varten.
                      Jos et saanut sähköpostia, odota muutama minuutti ja tarkista myös roskapostikansiosi.
                      Mikäli et löydä viestiä, voit yrittää uudestaan.
                  </p>
                  <p>
                      <Button className="button" href="/hakemukset/salasanan-palautus" target="_self"
                              onClick={this.clearPasswordReset}
                      >Lähetä uusi viesti</Button>
                  </p>
              </div>);
    }
    return (
        <div className="login-page" id="loginPage">
            <div className="form login-container">
                <p>Tämän lomakkeen avulla voit lähettää linkin sähköpostiisi salasanan palauttamista varten.</p>
                <br />
                {inputSection}
                {errorSection}
                <span className="message">
                    <Link to="/hakemukset/kirjautuminen">Ei tarvetta salasanan palautukselle?<br />Siirry kirjautumissivulle.
                </Link></span>
                <br />
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({ passwordManagement: state.passwordManagement });

export default connect(mapStateToProps)(ResetPasswordPageContainer);
