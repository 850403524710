import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, UncontrolledTooltip } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
// Import actions
import SingleDraftDetails from './SingleDraftDetails';

import { fetchDrafts, fetch20Drafts, fetch50Drafts, textSearch, fetchDraft,
    sendInstructionEmailRequest, deleteDraft }
	from '../../../actions/ManagementDraftActions';
import { fetchCompetitionsWithAccess } from '../../../actions/CompetitionActions';
import { checkSession } from '../../../actions/AuthenticationActions';
import kareliaLogo from '../../../shared/logos/Karelia_logo.png';
import uefLogo from '../../../shared/logos/UEF_logo.png';
import riveriaLogo from '../../../shared/logos/Riveria_logo.png';
const FileSaver = require('file-saver');
const b64toBlob = require('b64-to-blob');

class DraftListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            competitionList: null,
            competition: '',
            draftCount: 0,
            competitionName: '',
            draftList: null,
            name: '',
            email: '',
            phone: '',
            organisation: '',
            schoolImgSrc: '',
            schoolImgTooltip: '',
            roleText: '',
            members: '',
            matesQuantity: '',
            title: '',
            description: '',
            heardFrom: '',
            fileName: '',
            fileData: '',
            cuid: '',
            selectedItems: [],
            selectedItemsNeedsReset: false,
            sentFeedbackCount: 0,
            isSendingFeedbacks: false,
            isFetchingDrafts: false,
            draftsFetchedOnce: false,
            isChangingCompetition: false,
            draftsToFetch: '20',
            searchTerm: '',
            searchIsFocused: false,
            scrollPosition: 0,
            modal: false,
            deleteConfirmModal: false,
            deleteConfirmationChecked: false,
            deleteSuccesModal: false,
            feedbackModal: false,
            feedbackList: '',
            feedbackMessage: '',
            feedbackPristine: true,
            feedbackValid: false,
            feedbackSubmitter: this.props.authentication.email,
            feedbackSentTime: '',
            feedbackErrorVisibility: false
        };
        // bound functions
        this.timeFormat = this.timeFormat.bind(this);
        this.competitionList = this.competitionList.bind(this);
        this.toggleFetchRadioBtn = this.toggleFetchRadioBtn.bind(this);
        this.searchTermChanged = this.searchTermChanged.bind(this);
        this.fetchAllDrafts = this.fetchAllDrafts.bind(this);
        this.fetch20Drafts = this.fetch20Drafts.bind(this);
        this.fetch50Drafts = this.fetch50Drafts.bind(this);
        this.fetchCompetitionsList = this.fetchCompetitionsList.bind(this);
        this.competitionListItem = this.competitionListItem.bind(this);
        this.focusCompetitionSelector = this.focusCompetitionSelector.bind(this);
        this.fetchSelectedCompetition = this.fetchSelectedCompetition.bind(this);
        this.fetchDraftFunction = this.fetchDraftFunction.bind(this);
        this.DraftListItem = this.DraftListItem.bind(this);
        this.DraftList = this.DraftList.bind(this);
        this.sendInstructionMailFunction = this.sendInstructionMailFunction.bind(this);
        this.sendMultiInstructionMailFunction = this.sendMultiInstructionMailFunction.bind(this);
        this.dispatchNewFeedback = this.dispatchNewFeedback.bind(this);
        this.dispatchNewMultiFeedback = this.dispatchNewMultiFeedback.bind(this);
        this.downloadPdf = this.downloadPdf.bind(this);
        this.checkUserSession = this.checkUserSession.bind(this);
        this.fetchBySelectedQuantity = this.fetchBySelectedQuantity.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
        this.deleteDraftConfirmed = this.deleteDraftConfirmed.bind(this);
        this.checkDeleteConfirm = this.checkDeleteConfirm.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleDeleteSuccess = this.toggleDeleteSuccess.bind(this);
        this.toggleFeedback = this.toggleFeedback.bind(this);
        this.cleanupFeedbackEditor = this.cleanupFeedbackEditor.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleSelectAllItems = this.toggleSelectAllItems.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.FeedbackListItem = this.FeedbackListItem.bind(this);
        this.feedbackEdited = this.feedbackEdited.bind(this);
    }

    componentDidMount() {
        this.checkUserSession();
        this.fetchCompetitionsList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.competitions !== this.props.competitions.competitionListWithAccess) {
            this.setState({
                competitionList: this.competitionList(nextProps.competitions.competitionListWithAccess),
                },
                this.focusCompetitionSelector
            );
        }
        // state isFetchingDrafts enables/disables the search UI elements, thus preventing 'flooding'.
        if (nextProps.drafts.isFetchingDrafts !== this.props.drafts.isFetchingDrafts) {
            this.setState({
                isFetchingDrafts: !this.state.isFetchingDrafts,
                },
                this.focusCompetitionSelector
            );
        }
        // If settings modal state changed, fetchCompetionList, thus updating incase rights removed from current user.
        if (nextProps.settingsModal !== this.props.settingsModal && !nextProps.settingsModal) {
            this.fetchCompetitionsList();
        }
        if (nextProps.drafts.singleDraft !== this.props.drafts.singleDraft) {
            const singleDraft = nextProps.drafts.singleDraft.draft;
            const newDate = this.timeFormat(new Date(singleDraft.dateAdded));
            let phoneText = '';
            if (singleDraft.phone !== undefined) {
                phoneText = singleDraft.phone;
            }
            const numberOfMates = singleDraft.members - 1;
            let matesQuantityValue = numberOfMates;
            if (singleDraft.members === '+10') {
                matesQuantityValue = '>9';
            }
            const organisation = singleDraft.organisation;
            const organisationArray = organisation.split('|');
            const school = organisationArray[0];
            const role = organisationArray[1];
            let roleTextValue = organisationArray[2];
            let schoolImgSrcValue = kareliaLogo;
            let schoolImgTooltipValue = 'karelia';
            if (school.toLowerCase().includes('yliopisto')) {
                schoolImgSrcValue = uefLogo;
                schoolImgTooltipValue = 'uef';
            }
            else if (school.toLowerCase().includes('riveria')) {
                schoolImgSrcValue = riveriaLogo;
                schoolImgTooltipValue = 'riveria';
            }
            roleTextValue = role + ': ' + roleTextValue;
            if (organisationArray.length !== 3) {
                roleTextValue = '';
            }
            this.setState({
                competition: singleDraft.competition,
                sentDate: newDate,
                name: singleDraft.name,
                email: singleDraft.email,
                phone: phoneText,
                organisation: singleDraft.organisation,
                schoolImgSrc: schoolImgSrcValue,
                schoolImgTooltip: schoolImgTooltipValue,
                roleText: roleTextValue,
                members: singleDraft.members,
                matesQuantity: matesQuantityValue,
                title: singleDraft.title,
                description: singleDraft.description,
                heardFrom: singleDraft.heardFrom,
                fileName: singleDraft.fileName,
                fileData: singleDraft.fileData,
                cuid: singleDraft.cuid,
                feedbackList: singleDraft.feedbacks,
                modal: !this.state.modal,
            });
        }
        if (nextProps.drafts.sendInstructionsStatusErrorCuid != this.props.drafts.sendInstructionsStatusErrorCuid) {
            if (nextProps.drafts.sendInstructionsStatus === 2) {
                if (this.state.modal) {
                    const singleFeedBackArray = this.state.feedbackList;
                    singleFeedBackArray.push('<p class="feedback-submit-error">Palautteen lähettämisessä tapahtui virhe!</p>');
                    this.setState({
                        feedbackList: singleFeedBackArray,
                        feedbackErrorVisibility: true
                    });
                    this.cleanupFeedbackEditor();
                    return;
                }
                const feedbackArray = this.state.selectedItems;
                for (let i = 0; i < feedbackArray.length; i++) {
                    if (feedbackArray[i].cuid == nextProps.drafts.sendInstructionsStatusErrorCuid) {
                        feedbackArray[i].feedbackList.push(['<p class="feedback-submit-error">Palautteen lähettämisessä tapahtui virhe!</p>']);
                    }
                }
                let sentCount = this.state.sentFeedbackCount;
                sentCount += 1;
                this.setState({
                        selectedItems: feedbackArray,
                        feedbackErrorVisibility: true,
                        sentFeedbackCount: sentCount,
                        isSendingFeedbacks: true
                    },
                     this.cleanupFeedbackEditor
                );
            }
        }
        if (nextProps.drafts.sendInstructionsStatusSuccessCuid !== this.props.drafts.sendInstructionsStatusSuccessCuid) {
            if (nextProps.drafts.sendInstructionsStatus === 3) {
                // Add the sent feedback to the list, thus rendering it in the modal instantly.
                const feedBackMessage = this.state.feedbackMessage;
                const feedBackMsgWithSubmitter = '<div class="feedback-sent-details">' +
                    '<p class="sent-text-item"><span class="sent-text-item">' +
                    '<i class="fas fa-envelope label-icon"></i>' + this.state.feedbackSubmitter + '</span></p>' +
                    '<p class="sent-text-item"><span class="sent-text-item">' +
                    '<i class="fas fa-clock label-icon"></i>' + this.state.feedBackSentTime + '</span></p>' +
                    '</div>' + feedBackMessage;
                // FeedbackList is used for showing feedbacks of a single draft.
                if (this.state.modal) {
                    const singleFeedBackArray = this.state.feedbackList;
                    singleFeedBackArray.push(feedBackMsgWithSubmitter);
                    this.setState({
                        feedbackList: singleFeedBackArray,
                    });
                    for (let i = 0; i < nextProps.drafts.data.length; i++) {
                        if (nextProps.drafts.data[i].cuid === this.state.cuid) {
                            nextProps.drafts.data[i].feedbacks.push([feedBackMsgWithSubmitter]);
                        }
                    }
                    this.cleanupFeedbackEditor();
                    return;
                }
                const feedbackArray = this.state.selectedItems;
                for (let i = 0; i < feedbackArray.length; i++) {
                    if (feedbackArray[i].cuid === nextProps.drafts.sendInstructionsStatusSuccessCuid) {
                        feedbackArray[i].feedbackList.push([feedBackMsgWithSubmitter]);
                    }
                }
                let sentCount = this.state.sentFeedbackCount;
                sentCount += 1;
                this.setState({
                        selectedItems: feedbackArray,
                        sentFeedbackCount: sentCount,
                        isSendingFeedbacks: true
                    },
                     this.cleanupFeedbackEditor
                );
            }
        }
        // Display an alert according to update results.
        if (nextProps.drafts.deleteDraftStatus !== this.props.drafts.deleteDraftStatus) {
            if (nextProps.drafts.deleteDraftStatus === 2) {
                this.fetchBySelectedQuantity();
                alert('Draftin poistamisessa tapahtui virhe! Jos hakemus kuitenkin poistui listasta, ' +
                    'tapahtui poistamisen lokituksessa virhe.');
            }
            else if (nextProps.drafts.deleteDraftStatus === 3) {
                this.fetchBySelectedQuantity();
                this.setState({
                    deleteSuccesModal: true,
                });
            }
        }
        if (nextProps.competitions.deleteCompetitionStatus !== this.props.competitions.deleteCompetitionStatus) {
            if (nextProps.competitions.deleteCompetitionStatus === 3)
            {
                this.fetchBySelectedQuantity();
            }
        }
    }

    checkUserSession() {
        const { dispatch } = this.props;
        dispatch(checkSession());
    }

    toggleFetchRadioBtn(event)
    {
        if (event.target.value === '20')
        {
            this.setState({
                draftsToFetch: '20'
                },
                this.fetchBySelectedQuantity
            );
        }
        else if (event.target.value === '50')
        {
            this.setState({
                draftsToFetch: '50'
                },
                this.fetchBySelectedQuantity
            );
        }
        else if (event.target.value === 'all')
        {
            this.setState({
                draftsToFetch: 'all'
            },
            this.fetchBySelectedQuantity
            );
        }
    }

    fetchBySelectedQuantity() {
        // Check session and reset possible search term.
        this.checkUserSession();
        this.setState({
            searchTerm: ''
        });
        this.selectNone();
        if (this.state.draftsToFetch === '20') {
            this.fetch20Drafts();
        }
        else if (this.state.draftsToFetch === '50') {
            this.fetch50Drafts();
        }
        else if (this.state.draftsToFetch === 'all') {
            this.fetchAllDrafts();
        }
    }

    fetchCompetitionsList() {
        const { dispatch } = this.props;
        this.setState({
            competitionList: null
        });
        dispatch(fetchCompetitionsWithAccess(this.props.authentication));
    }

    fetchSelectedCompetition(e) {
        // Get selected text value for showing it when opening drafts.
        const selectElement = document.getElementById('draftCompetition');
        const selectedIndex = selectElement.selectedIndex;
        this.setState({
            competitionName: selectElement.options[selectedIndex].text,
            isChangingCompetition: true,
        });
        if (e.target.value === 'default')
        {
            this.setState({
                competition: '',
                },
                // Callback to handleValidSubmit once setState is done for.
                this.fetchBySelectedQuantity
            );
        }
        else
        {
            this.setState({
                competition: e.target.value,
                draftCount: selectElement.options[selectedIndex].getAttribute('draftcount'),
                },
                // Callback to handleValidSubmit once setState is done for.
                this.fetchBySelectedQuantity
            );
        }
    }

    searchTermChanged(event) {
        this.setState({
            searchTerm: event.target.value,
            searchIsFocused: true
        });
        // If search is at least 3 chars long we will perform search.
        if (event.target.value.length > 2)
        {
            // If input is not changed in 0.25 s, perform the search.
            const search = event.target.value;
            setTimeout(function () {
                if (search === this.state.searchTerm) {
                    this.selectNone();
                    const { dispatch } = this.props;
                    dispatch(textSearch(this.state));
                }
            }.bind(this), 250);
        }
        // Re-fetch drafts if search is emptied.
        if (event.target.value.length === 0)
        {
            this.fetchBySelectedQuantity();
        }
    }

    fetchAllDrafts() {
        const { dispatch } = this.props;
        dispatch(fetchDrafts(this.state));
    }

    fetch20Drafts() {
        const { dispatch } = this.props;
        dispatch(fetch20Drafts(this.state));
    }

    fetch50Drafts() {
        const { dispatch } = this.props;
        dispatch(fetch50Drafts(this.state));
    }

    focusCompetitionSelector() {
        // Give focus to competition selector, if not initial load & changing competition.
        if (this.state.draftsFetchedOnce && this.state.isChangingCompetition && this.state.searchTerm.length === 0) {
            document.getElementById('draftCompetition').focus();
        }
        else {
            this.setState({
                draftsFetchedOnce: true,
                isChangingCompetition: false,
            });
        }
        if (!this.state.isFetchingDrafts) {
            this.setState({
                    draftList: this.DraftList()
                },
            );
        }
        const textField = document.getElementById('textFilter');
        if (this.state.searchIsFocused && textField !== null && !this.state.draftsFetchedOnce && !this.state.isChangingCompetition) {
            document.getElementById('textFilter').focus();
        }
    }

    fetchDraftFunction(basicDetails) {
        // Pass cuid and logged in users email as data to fetchDraft.
        const draft = { cuid: basicDetails.cuid, userEmail: this.props.authentication.email };
        const { dispatch } = this.props;
        dispatch(fetchDraft(draft));
    }

    sendInstructionMailFunction() {
        this.checkUserSession();
        this.setState({
                feedbackValid: false,
                feedbackPristine: true,
                feedBackSentTime: this.timeFormat(new Date()),
            },
                // Callback
                this.dispatchNewFeedback
        );
    }

    sendMultiInstructionMailFunction() {
        this.checkUserSession();
        this.setState({
                feedbackValid: false,
                feedbackPristine: true,
                feedBackSentTime: this.timeFormat(new Date()),
            },
            // Callback
            this.dispatchNewMultiFeedback
        );
    }

    dispatchNewFeedback() {
        const { dispatch } = this.props;
        document.body.style.cursor = 'wait';
        const feedbackData = { email: this.state.email, cuid: this.state.cuid, feedbackMessage: this.state.feedbackMessage,
            feedBackSentTime: this.state.feedBackSentTime, feedbackSubmitter: this.state.feedbackSubmitter };
        dispatch(sendInstructionEmailRequest(feedbackData));
    }

    dispatchNewMultiFeedback() {
        const { dispatch } = this.props;
        const submitter = this.state.feedbackSubmitter;
        const message = this.state.feedbackMessage;
        document.body.style.cursor = 'wait';
        for (let i = 0; i < this.state.selectedItems.length; i++) {
            const feedbackData = { email: this.state.selectedItems[i].email, cuid: this.state.selectedItems[i].cuid,
                feedBackSentTime: this.state.feedBackSentTime, feedbackMessage: message, feedbackSubmitter: submitter };
            dispatch(sendInstructionEmailRequest(feedbackData));
        }
    }

    deleteDraft() {
        this.toggleDeleteConfirmModal();
    }

    checkDeleteConfirm() {
        this.setState({
            deleteConfirmationChecked: !this.state.deleteConfirmationChecked
        });
    }

    deleteDraftConfirmed() {
        this.toggleDeleteConfirmModal();
        const toDelete = { cuid: this.state.cuid, dateSent: this.state.sentDate, userEmail: this.props.authentication.email };
        const { dispatch } = this.props;
        dispatch(deleteDraft(toDelete));
    }

    toggle(basicDetails, scrollToFeedback) {
        // We only fetch data if we are opening the modal, not closing it.
        if (!this.state.modal)
        {
            this.checkUserSession();
            this.setState({
                scrollPosition: window.pageYOffset
            });
            // This will also toggle the modal.
            this.fetchDraftFunction(basicDetails.draft);
            // Scroll to feedbacks section if opening via feedback links.
            if (scrollToFeedback) {
                setTimeout(function () {
                    const feedBackSection = document.getElementById('feedbacks');
                    feedBackSection.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
                }, 500);
            }
        }
        else
        {
            this.setState({
                modal: !this.state.modal,
                feedbackErrorVisibility: false
            });
            window.scrollTo(0, this.state.scrollPosition);
        }
    }

    toggleDeleteConfirmModal() {
        this.setState({
            deleteConfirmationChecked: false,
            deleteConfirmModal: !this.state.deleteConfirmModal,
        });
        window.scrollTo(0, this.state.scrollPosition);
    }

    toggleDeleteSuccess() {
        this.setState({
            deleteSuccesModal: !this.state.deleteSuccesModal,
            modal: !this.state.modal,
        });
        window.scrollTo(0, this.state.scrollPosition);
    }

    selectItem(props) {
        const selectAll = document.getElementById('selectAll');
        let array = this.state.selectedItems;
        if (!array.some((e) => e.cuid === props.draft.cuid)) {
            array.push({ cuid: props.draft.cuid, email: props.draft.email, name: props.draft.name, title: props.draft.title,
                feedbackList: props.draft.feedbacks });
            this.setState({
                selectedItems: array
            });
            if (document.getElementsByClassName('table-checkbox').length === array.length) {
                selectAll.checked = true;
            }
        }
        else {
            array = array.filter(function (obj) {
                return obj.cuid !== props.draft.cuid;
            });
            this.setState({
                selectedItems: array
            });
            selectAll.checked = false;
        }
    }

    toggleSelectAllItems() {
        let array = this.state.selectedItems;
        const selectAll = document.getElementById('selectAll');
        if (array.length !== 0) {
            array = [];
            this.setState({
                selectedItems: array
            });
            // loop through each input element and output the value of any checkbox elements
            for (let x = 0; x < document.getElementsByClassName('table-checkbox').length; x++) {
                document.getElementsByClassName('table-checkbox').item(x).checked = false;
                selectAll.checked = false;
                selectAll.click();
            }
        }
        else {
            for (let x = 0; x < document.getElementsByClassName('table-checkbox').length; x++) {
                document.getElementsByClassName('table-checkbox').item(x).click();
            }

            for (let t = 0; t < document.getElementsByClassName('table-checkbox').length; t++) {
                document.getElementsByClassName('table-checkbox').item(t).checked = true;
            }
        }
    }

    selectNone() {
        const selectAll = document.getElementById('selectAll');
        const array = [];
        this.setState({
            selectedItems: array
        });
        // loop through each input element and output the value of any checkbox elements
        for (let x = 0; x < document.getElementsByClassName('table-checkbox').length; x++) {
            document.getElementsByClassName('table-checkbox').item(x).checked = false;
            selectAll.checked = false;
            // selectAll.click();
        }
    }

    toggleFeedback() {
        if (this.state.feedbackModal && this.state.isSendingFeedbacks) {
            alert('Palautteiden lähetys on vielä kesken, ole hyvä ja odota hetki ennen lomakkeen sulkemista');
            return;
        }
        if (this.state.selectedItemsNeedsReset) {
            this.toggleSelectAllItems();
                this.setState({
                    selectedItemsNeedsReset: false,
                });
            if (this.state.searchTerm.length >= 3) {
                const { dispatch } = this.props;
                dispatch(textSearch(this.state));
                const selectAll = document.getElementById('selectAll');
                selectAll.checked = false;
            }
            else {
                this.fetchBySelectedQuantity();
            }
        }
        this.setState({
            feedbackModal: !this.state.feedbackModal,
            feedbackMessage: '',
            feedbackPristine: true,
            feedbackValid: false,
            feedbackErrorVisibility: false
        });
    }

    cleanupFeedbackEditor() {
        if (this.state.modal) {
            this.setState({
                feedbackMessage: '',
                feedbackPristine: true,
                feedbackValid: false,
            });
            try {
                tinymce.EditorManager.get('description').setContent(''); // eslint-disable-line
            }
            catch (e) {
                console.log(e);
            }
            document.body.style.cursor = 'default';
        }
        else if (this.state.selectedItems.length === this.state.sentFeedbackCount) {
            this.setState({
                feedbackMessage: '',
                feedbackPristine: true,
                feedbackValid: false,
                selectedItemsNeedsReset: true,
                sentFeedbackCount: 0,
                isSendingFeedbacks: false
            });
            try {
                tinymce.EditorManager.get('description').setContent(''); // eslint-disable-line
            }
            catch (e) {
                console.log(e);
            }
            document.body.style.cursor = 'default';
        }
    }

    feedbackEdited(e, editor) {
        const newContent = editor.getContent();
        // New row for example adds two characters, lets make the limit 15.
        this.setState({
            feedbackMessage: newContent,
            feedbackPristine: false,
        });
        if (newContent.length > 10)
        {
            this.setState({ feedbackValid: true });
        }
        else {
            // If we set the state to match invalidInput the error text will be shown in the UI.
            this.setState({ feedbackValid: false, });
        }
    }

    // Generates a .pdf file from base64 and downloads it.
    downloadPdf = function (base64Data, fileName) {
        const blob = b64toBlob(base64Data, 'application/pdf');
        FileSaver.saveAs(blob, fileName);
    };

    competitionListItem(competition) {
        let setDraftCount = 0;
        if (this.state.competition === '') {
            setDraftCount = competition.competition.draftCount;
            if (setDraftCount === undefined) {
                setDraftCount = 9999;
            }
            this.setState({
                    competition: competition.competition._id,
                    competitionName: competition.competition.nameFi,
                    draftCount: setDraftCount,
                },
                // Callback
                this.fetchBySelectedQuantity
            );
        }
        return (
            <option value={competition.competition._id} draftcount={competition.competition.draftCount === undefined ? 9999 :
                competition.competition.draftCount}>{competition.competition.nameFi} ({ competition.competition.draftCount === undefined ?
                '?' : competition.competition.draftCount })</option>
);
    }

    competitionList(competitionList) {
        const competitions = competitionList;
        let noCompetitionsListed = '';
        if (this.state.competition === '' && !this.props.competitions.isFetchingCompetitions) {
            noCompetitionsListed = <option value="default" defaultChecked>Sinulle ei ole myönnetty käyttöoikeuksia hakukohteisiin.</option>;
        }
        // let deleteDraftBtn = this.deleteDraft.bind(this, this.state);
        return (
            <FormGroup>
                <Input type="select" name="draftCompetition" id="draftCompetition" onChange={this.fetchSelectedCompetition}
                       value={this.state.competition}>
                    {
                        competitions.map((competition) => (
                            <this.competitionListItem
                                competition={competition}
                                key={competition._id}
                            />
                        ))
                    }
                    { noCompetitionsListed }
                </Input>
            </FormGroup>
        );
    }

    // Formats date as dd.mm.yyyy hh:mm
    // https://stackoverflow.com/questions/19346405/jquery-how-to-get-hhmmss-from-date-object
    timeFormat(d) {
        // Get dd.mm.yyyy hh:mm
        // January = 0.
        const days = (d.getDate());
        const months = (d.getMonth() + 1);
        const years = (d.getFullYear());
        const hours = this.formatTwoDigits(d.getHours());
        const minutes = this.formatTwoDigits(d.getMinutes());
        return days + '.' + months + '.' + years + ' klo ' + hours + '.' + minutes;
    }

    // Add 0 to h/m inputs if number is less than 10.
    formatTwoDigits(n) {
        return n < 10 ? '0' + n : n;
    }

    // DRAFT LIST ITEM
     DraftListItem(props) {
        // Note: If we try to assign prop.draft.dateAddded to equal newDate we get NAN on opening modal.
        const newDate = this.timeFormat(new Date(props.draft.dateAdded));
        const clickTitleToOpenModal = this.toggle.bind(this, props, false);
        const clickFeedbacksToOpenModal = this.toggle.bind(this, props, true);
        const clickSelectBtn = this.selectItem.bind(this, props);
        const selectBtn = <Input type="checkbox" className="item-checkbox table-checkbox" onChange={clickSelectBtn} />;
        let feedbacksBtn = <a className="feedback-text" href="#" target="_self" onClick={clickFeedbacksToOpenModal}>Palautteet: 0</a>;
        if (props.draft.feedbacks != null && props.draft.feedbacks !== undefined) {
            feedbacksBtn = <a className="feedback-text" href="#" target="_self" onClick={clickFeedbacksToOpenModal}>Palautteet: {props.draft.feedbacks.length} </a>;
        }
        return (
            <tr>
                <td className="select-item-td">{selectBtn} {feedbacksBtn}</td>
                <td><a href="#" target="_self" onClick={clickTitleToOpenModal}>{props.draft.title}</a></td>
                <td>{props.draft.name}</td>
                <td>{newDate}</td>
            </tr>
        );
    }

    FeedbackListItem(props) {
        // Note: If we try to assign prop.draft.dateAddded to equal newDate we get NAN on opening modal.
        const title = 'title' + props.feedback.cuid;
        const sender = 'sender' + props.feedback.cuid;
        return (
            <div className="single-application-feedback">
                <FormGroup>
                    <div className="feedback-title">
                    <p className="sent-text-item"><span id={title} className="sent-text-item">
                            <i className="fas fa-pencil-alt label-icon" /> {props.feedback.title}</span></p>
                    <p className="sent-text-item"><span id={sender} className="sent-text-item">
                            <i className="fas fa-user-alt label-icon" /> {props.feedback.name}</span></p>
                    </div>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target={title}>
                        Idean nimi
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target={sender}>
                        Lähettäjän nimi
                    </UncontrolledTooltip>
                </FormGroup>
                <FormGroup id="feedbacks">
                    {props.feedback.feedbackList !== undefined && props.feedback.feedbackList.length !== 0 && props.feedback.feedbackList != null ?
                        null :
                            <div className="single-feedback" tabIndex={0}><p className="no-feedbacks">Ei palautteita.</p></div>}
                    {Object.keys(props.feedback.feedbackList).map((i) => (
                        <div className="single-feedback" key={i}>
                            <FormGroup>
                                <div tabIndex={0} dangerouslySetInnerHTML={{ __html: props.feedback.feedbackList[i].toString() }} />
                            </FormGroup>
                        </div>
)
                    )}
                </FormGroup>
            </div>
        );
    }

     DraftList() {
     const { drafts } = this.props;
     // let deleteDraftBtn = this.deleteDraft.bind(this, this.state);
      return (
            <tbody>
            {this.props.drafts.data.length === 0 && !this.state.isFetchingDrafts ? (
                    <tr className="tr-no-results">
                        <td className="td-no-results" colSpan="4">Ei hakutuloksia.</td>
                    </tr>
                  ) : null }
            {this.state.isFetchingDrafts ? (
                <tr className="tr-no-results">
                    <td className="td-no-results" colSpan="4">Ladataan....</td>
                </tr>
              ) : null }
                {!this.state.isFetchingDrafts ?
                drafts.data.map((draft) => (
                <this.DraftListItem
                draft={draft}
                key={draft.cuid}
                />
                )) : null }
            </tbody>
      );
    }

  render() {
      const screenWidth = window.innerWidth;
          let searchHelpText = '';
          if (this.state.searchTerm.length !== 0 && this.state.searchTerm.length < 3) {
              searchHelpText = 'Kirjoita vähintään 3 merkkiä...';
          }
          else if (this.state.searchTerm.length > 2 && this.props.drafts.data.length > 1) {
              searchHelpText = this.props.drafts.data.length + ' hakutulosta.';
          }
          else if (this.state.searchTerm.length > 2 && this.props.drafts.data.length === 1) {
              searchHelpText = this.props.drafts.data.length + ' hakutulos.';
          }
          else if (this.state.searchTerm.length > 2 && this.props.drafts.data.length === 0) {
              searchHelpText = 'Tekstihaku ei löytänyt hakutuloksia.';
          }
          const clickToggleSelectAll = this.toggleSelectAllItems.bind(this);
          const toggleSelectAllBtn = <Input id="selectAll" type="checkbox" className="item-checkbox" disabled={this.state.draftCount == 0 || this.props.drafts.data.length === 0} onChange={clickToggleSelectAll} />;
          let feedbacksBtn = <span className="th-span">Palautteet</span>;
          if (this.state.selectedItems.length !== 0) {
              feedbacksBtn = <Button className="th-btn" onClick={this.toggleFeedback}>Lähetä palautetta</Button>;
          }
          let deleteBtn = '';
          if (this.props.authentication.isAdmin) {
              deleteBtn = <Button color="danger" size="sm" style={{ marginTop: '35' + 'px', marginLeft: '.1' + 'em' }} onClick={this.deleteDraft}>Poista hakemus</Button>;
          }
        const toggleConfirmDeleteBtn = this.toggleDeleteConfirmModal.bind(this);
        return (
          <div>
              <Label className="card-title management-select-title" for="draftCompetition"><i className="fas fa-bullseye label-icon" /> Hakukohde</Label>
              <section className="competition-filter-section">
              {this.state.competitionList}
              {this.state.draftCount > 0 ? (
                  <FormGroup>
                      <Input type="text" value={this.state.searchTerm} id="textFilter" onChange={this.searchTermChanged.bind(this)}
                             disabled={this.state.isFetchingDrafts && this.state.searchTerm.length === 0}
                             placeholder="Hae ideaa/lähettäjää, väh. 3 merkkiä." />{' '}
                  </FormGroup>
                )
                  : null }
              {this.state.draftCount > 20 && this.state.searchTerm.length == 0 ? (
                  <FormGroup className="fetch-quantity-group" tag="fieldset" inline onChange={this.toggleFetchRadioBtn.bind(this)}>
                      <Label check style={{ marginRight: '6' + 'px' }}>
                          <Input type="radio" name="radio1" value="20" defaultChecked disabled={this.state.isFetchingDrafts} />{' '}
                            20 {screenWidth > 529 ? 'viimeisintä' : ''}
                      </Label>
                      {this.state.draftCount > 20 ? (
                            <Label check style={{ marginRight: '6' + 'px' }}>
                              <Input type="radio" name="radio1" value="50" disabled={this.state.isFetchingDrafts} />{' '}
                              {this.state.draftCount < 51 ? 'Kaikki' : '50'} {screenWidth > 529 && this.state.draftCount > 50 ? 'viimeisintä' : ''}
                          </Label> )
                          : '' }
                      {this.state.draftCount > 50 ? (
                          <Label check> <Input type="radio" name="radio1" value="all" disabled={this.state.isFetchingDrafts} />{' '}
                            Kaikki {screenWidth > 365 ? 'hakemukset' : ''}
                          </Label>
                        )
                          : '' }
                  </FormGroup>
                )
                    : (
                <Label hidden={this.state.searchTerm.length === 0} className="result-quantity"><strong>
                      {searchHelpText}
                 </strong></Label>
                )}
              </section>
              <Table>
                  <thead>
                      <tr className="table-title">
                          <th className="select-item-td select-item-th">
                              {toggleSelectAllBtn}
                              <i className="feedback-th-icon fas fa-comment label-icon" /> {feedbacksBtn}
                              <UncontrolledTooltip placement="bottom-start" hideArrow target="selectAll">
                                  {this.state.selectedItems.length === 0 ?
                                      'Valitse kaikki' :
                                      'Poista valinta' }
                              </UncontrolledTooltip>
                          </th>
                          <th><i className="fas fa-pencil-alt label-icon" /> Idean nimi</th>
                          <th><i className="fas fa-user-alt label-icon" /> Lähettäjä</th>
                          <th><i className="fas fa-clock label-icon" /> Lähetysaika</th>
                      </tr>
                  </thead>
                  {this.state.draftList}
                  {this.state.selectedItems.length !== 0 ? (
                      <tfoot><tr className="bottom-feedback-btn"><td colSpan="4"><i className="feedback-th-icon fas fa-comment label-icon" />
                          <Button className="th-btn" onClick={this.toggleFeedback}>Lähetä palautetta</Button></td></tr></tfoot>
                    )
                      : null }
              </Table>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg always-lg" backdrop="static">
                  <ModalHeader toggle={this.toggle}>Hakemuksen tiedot</ModalHeader>
                  <ModalBody>
                      <SingleDraftDetails form={this.state} />
                      <Form>
                          <FormGroup className="tinymce-editor feedback-editor">
                              <Editor
                                  apiKey="gfe4wvrlk2ftcypnv28g0apfl37t4v1r4fbmyi25d68o9tqv"
                                  id="description"
                                  initialValue={this.state.feedbackMessage}
                                  init={{
                                      language_url: 'https://olli-suutari.github.io/tinyMCE-4-translations/fi.js',
                                      language: 'fi',
                                      plugins: 'autolink link autoresize lists',
                                      toolbar1: 'bold italic underline forecolor fontsizeselect link',
                                      toolbar2: 'undo redo alignleft aligncenter alignright alignjustify outdent indent',
                                      fontsize_formats: '11pt 12pt 13pt 15pt',
                                      textcolor_cols: '5',
                                      autoresize_bottom_margin: 50,
                                      default_link_target: '_blank',
                                      menubar: false,
                                      statusbar: false,
                                      branding: true,
                                      mobile: {
                                          theme: 'silver'
                                      }
                                  }}
                                  onKeyUp={this.feedbackEdited}
                                  onChange={this.feedbackEdited}
                              />
                              {!this.state.feedbackValid && !this.state.feedbackPristine ? (
                                <p style={{ color: ' red' }}>
                                                                  Tarkista viestin sisältö.</p>
                                ) : null}
                                                              {this.state.feedbackErrorVisibility ? (
                                <p className="feedback-submit-error">
                                                                  Palautteen lähettäminen epäonnistui. Sulje tämä lomake, ja yritä lähettää palautetta uudestaan.</p>
                                ) : null }
                          </FormGroup>
                          <Button color="success" size="sm" className="send-feedback" disabled={!this.state.feedbackValid || this.state.feedbackErrorVisibility}
                                  onClick={this.sendInstructionMailFunction}>Lähetä palaute
                          </Button>
                      </Form>
                      {deleteBtn}
                  </ModalBody>
                  <ModalFooter>
                      <Button color="primary" onClick={this.toggle}>Sulje</Button>
                  </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.deleteConfirmModal} toggle={this.toggleDeleteConfirmModal}>
                  <ModalHeader className="confirm-delete">Draftin poistamisen vahvistus</ModalHeader>
                  <br />
                  <ModalBody>
                      { this.state.userToDelete !== '' ?
                          <p>Olet poistamassa henkilön {this.state.name} lähettämää hakemusta, haluatko varmasti poistaa sen?</p> :
                          null }
                      <br />
                      <Form>
                          <FormGroup check>
                              <Input type="checkbox" className="confirm-delete-checkbox" checked={this.state.deleteConfirmationChecked}
                                     onChange={this.checkDeleteConfirm.bind(this)} />{' '}
                              <div style={{ marginLeft: 40 + 'px' }}>
                                  <strong>Haluan varmasti poistaa tämän hakemuksen.</strong>
                              </div>
                              <br />
                          </FormGroup>
                          <br />
                          <Button color="info" onClick={toggleConfirmDeleteBtn}>Peruuta</Button>
                          { this.state.deleteConfirmationChecked === true ? (
                              <Button color="danger" style={{ float: 'right' }} onClick={this.deleteDraftConfirmed}>Poista hakemus
                              </Button>
                            ) : <Button color="danger" style={{ float: 'right' }} disabled>Poista hakemus</Button> }
                      </Form>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.deleteSuccesModal} toggle={this.toggleDeleteSuccess}>
                  <ModalHeader className="modal-success">Hakemuksen poistaminen onnistui</ModalHeader>
                  <br />
                  <ModalBody><p>Hakemus poistettiin onnistuneesti.</p></ModalBody>
                  <ModalFooter className="modal-success">
                      <Button color="primary" onClick={this.toggleDeleteSuccess}>OK</Button>
                  </ModalFooter>
              </Modal>
              <Modal className="modal-lg always-lg" isOpen={this.state.feedbackModal} toggle={this.toggleFeedback}>
                  <ModalHeader className="modal-success" toggle={this.toggleFeedback}>Palautteet</ModalHeader>
                  <ModalBody>
                      <Form>
                          { this.state.selectedItems.length === 0 ? (
                              <FormGroup id="feedbacks">
                                  <p>Ladataan....</p>
                              </FormGroup>
                            ) :
                              this.state.selectedItems.map((draft) => (
                                  <this.FeedbackListItem
                                      feedback={draft}
                                      key={draft.cuid}
                                  />
                              ))}
                          <FormGroup className="tinymce-editor feedback-editor">
                              <Editor
                                  apiKey="gfe4wvrlk2ftcypnv28g0apfl37t4v1r4fbmyi25d68o9tqv"
                                  id="description"
                                  initialValue={this.state.feedbackMessage}
                                  init={{
                                      language_url: 'https://olli-suutari.github.io/tinyMCE-4-translations/fi.js',
                                      language: 'fi',
                                      plugins: 'autolink link autoresize lists',
                                      toolbar1: 'bold italic underline forecolor fontsizeselect link',
                                      toolbar2: 'undo redo alignleft aligncenter alignright alignjustify outdent indent',
                                      fontsize_formats: '11pt 12pt 13pt 15pt',
                                      textcolor_cols: '5',
                                      autoresize_bottom_margin: 50,
                                      default_link_target: '_blank',
                                      menubar: false,
                                      statusbar: false,
                                      branding: true,
                                      mobile: {
                                          theme: 'silver'
                                      }
                                  }}
                                  onKeyUp={this.feedbackEdited}
                                  onChange={this.feedbackEdited}
                              />
                              {!this.state.feedbackValid && !this.state.feedbackPristine ? (
                                <p style={{ color: ' red' }}>
                                                                  Tarkista viestin sisältö.</p>
                                ) : null}
                              {this.state.feedbackErrorVisibility ? (
                                <p className="feedback-submit-error">
                                              Yhden tai useamman palautteen lähettäminen epäonnistui. Epäonnistuneet lähetykset näkyvät tämän
                                              lomakkeen palautelistauksessa. Sulje lomake ja yritä lähettää palautetta uudestaan.</p>
                                ) : null }
                              </FormGroup>
                                  <Button color={this.state.isSendingFeedbacks ? 'warning' : 'success'} style={{float: 'right'}} size="sm" className="send-feedback" disabled={!this.state.feedbackValid ||
                                  this.state.feedbackErrorVisibility || this.state.selectedItems.length > 50 || this.state.isSendingFeedbacks}
                                      onClick={this.sendMultiInstructionMailFunction}>
                                        {this.state.isSendingFeedbacks ? 'Palautteita lähetetty: ' + this.state.sentFeedbackCount + '/' + this.state.selectedItems.length + '...' :
                                            'Lähetä palaute ' + this.state.selectedItems.length + ' hakijalle'}
                                  </Button>
                              {this.state.selectedItems.length > 50 ? (
                                <p style={{ color: ' red' }}>
                                          Voit lähettää palautetta kerralla enintään 50 hakijalle.</p>
                                ) : null}
                      </Form>
                  </ModalBody>
                  <ModalFooter className="modal-success">
                      <Button color="primary" onClick={this.toggleFeedback}>Sulje</Button>
                  </ModalFooter>
              </Modal>
          </div>
    );
  }
}

DraftListPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

DraftListPage.contextTypes = {
  router: PropTypes.object,
};

function mapStateToProps(store) {
    return {
        authentication: store.authentication,
        competitions: store.competitions,
    };
}

export default connect(mapStateToProps)(DraftListPage);
