import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormGroup, ModalBody } from 'reactstrap';

function TermsModal(props) {
	const { terms, captchaText } = props;
	return (
		<div>
			<ModalBody className="modalBody">
				<h4 tabIndex={0}><FormattedMessage id="termsTitle" /> </h4>
				<p tabIndex={0} dangerouslySetInnerHTML={{ __html: terms }} />
				<h4 tabIndex={0} className="cookies-title"><FormattedMessage id="cookiesTitle" /> </h4>
				<div className="captcha-div" tabIndex={0} dangerouslySetInnerHTML={{ __html: captchaText }} />
			</ModalBody>
		</div>
	);
}

TermsModal.propTypes = {
    terms: PropTypes.string.isRequired,
	captchaText: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ DraftActions: state.DraftActions });

export default connect(mapStateToProps)(TermsModal);
