import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
 } from 'reactstrap';
// Import Components
import Drafts from './components/DraftList';
import SettingsManagement from './components/settings/Settings';
import { checkSession, logUserOut } from '../../actions/AuthenticationActions';

export class Management extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSettingsModalVisible: false,
            timeout: setTimeout(function () { this.logUserOutFunction(); }.bind(this), 3600000),
        };
        this.checkUserSession = this.checkUserSession.bind(this);
        this.logUserOutFunction = this.logUserOutFunction.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.toggleSettingsModal = this.toggleSettingsModal.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.checkUserSession();
    }

    componentDidMount() {
        document.title = 'Hakemukset | Draft';
        // Set timeout, reload reset timer on key/mouse/ touch events
        // Based on: https://stackoverflow.com/questions/667555/detecting-idle-time-in-javascript-elegantly
        window.onload = this.resetTimer;
        document.onmousemove = this.resetTimer;
        document.onkeypress = this.resetTimer;
        document.onmousedown = this.resetTimer; // touchscreen presses
        document.ontouchstart = this.resetTimer;
        document.onclick = this.resetTimer; // touchpad clicks
        document.onscroll = this.resetTimer; // scrolling with arrow keys
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.authentication.isLoggedIn)
        {
            window.location = '/hakemukset/kirjautuminen';
        }
    }
    // The session will expire after an hour of inactivity (3600000 ms).
    resetTimer()
    {
        // Reset the timer
        clearTimeout(this.state.timeout);
        // Set a new timer. User will be logged out unless reset again.
        this.state.timeout = setTimeout(function () { this.logUserOutFunction(); }.bind(this), 3600000);
    }

    checkUserSession() {
        const { dispatch } = this.props;
        dispatch(checkSession());
    }

    logUserOutFunction() {
        const { dispatch } = this.props;
        dispatch(logUserOut());
    }

    toggleSettingsModal() {
        this.setState({
            isSettingsModalVisible: !this.state.isSettingsModalVisible
        });
    }

    render() {
        if (!this.props.authentication.isLoggedIn)
        {
            return (
                <div className="redirect">
                    <h2>Ladataan...</h2>
                </div>
            );
        }
        const { drafts } = this.props;
        const { email } = this.props.authentication;
        return (
            <div className="container">
                <Navbar className="management-nav" color="faded" light expand="md">
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown>
                                <DropdownToggle>
                                    <i className="menu-toggle fas fa-user-cog label-icon" alt="Valikko" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem disabled>{email}</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.logUserOutFunction}> <a href="#" target="_self">Kirjaudu ulos</a>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.toggleSettingsModal}> <a href="#" target="_self">Asetukset</a>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                </Navbar>
                <Drafts drafts={drafts} settingsModal={this.state.isSettingsModalVisible} />
                <Modal isOpen={this.state.isSettingsModalVisible} toggle={this.toggleSettingsModal} backdrop="static">
                    <ModalHeader toggle={this.toggleSettingsModal}>Asetukset</ModalHeader>
                    <ModalBody>
                        <SettingsManagement />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleSettingsModal}>Sulje</Button>
                    </ModalFooter>
                </Modal>
              </div>
        );
    }
}

Management.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
    return {
        drafts: store.draftManagement,
        authentication: store.authentication,
        userManagement: store.userManagement,
    };
}

export default connect(mapStateToProps)(Management);
