import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label, ModalBody, UncontrolledTooltip } from 'reactstrap';
import kareliaLogo from '../../../../shared/logos/Karelia_logo.png';
import uefLogo from '../../../../shared/logos/UEF_logo.png';
import riveriaLogo from '../../../../shared/logos/Riveria_logo.png';

// This is used to show the sent form details in the draftCreateWidget.
export class SentDraftDetails extends PureComponent {
    render() {
        const numberOfMates = this.props.form.members - 1;
        let matesQuantity = numberOfMates;
        if (this.props.form.members === '+10') {
            matesQuantity = '>9';
        }
        const organisation = this.props.form.organisation;
        const organisationArray = organisation.split('|');
        const school = organisationArray[0];
        let roleLabel = 'draftSenderStudent';
        if (organisationArray[1] === 'Henkilökunta') {
            roleLabel = 'draftSenderStaff';
        }
        else if (organisationArray[1] === 'Alumni') {
            roleLabel = 'draftSenderAlumni';
        }
        const roleText = organisationArray[2];
        let schoolImgSrc = kareliaLogo;
        if (school.toLowerCase().includes('yliopisto')) {
            schoolImgSrc = uefLogo;
        }
        else if (school.toLowerCase().includes('riveria')) {
            schoolImgSrc = riveriaLogo;
        }
        return (
            <ModalBody className="modalBody">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <section className="sent-section">
                <div className="form-help sent-info" tabIndex={0}>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <p><FormattedMessage id="formSentText" /> <a target="_blank" href="mailto:draft@karelia.fi">draft@karelia.fi</a></p>
                    <FormGroup>
                        <Label tabIndex={0} className="sent-text-item"><span id="sentHeardFrom"><i className="fas fa-question-circle label-icon" /> {this.props.form.heardFrom}</span></Label>
                        <UncontrolledTooltip placement="bottom-start" hideArrow target="sentHeardFrom">
                            <FormattedMessage id="draftHeardFrom" />
                        </UncontrolledTooltip>
                    </FormGroup>
                </div>
                </section>
                <section className="sent-section">
                    <div className="sent-description">
                        <p className="sent-text-item"><span id="sentCompetition" className="sent-text-item">
                            <i className="fas fa-bullseye label-icon" /> {this.props.form.selectedCompetitionText}</span></p>
                        <p className="sent-text-item"><span id="sentTitle" className="sent-text-item">
                            <i className="fas fa-pencil-alt label-icon" /> {this.props.form.title}</span></p>
                        {this.props.form.fileName.length > 0 &&
                        <p tabIndex={0} className="sent-text-item"><span id="sentPdf"><i className="fas fa-paperclip label-icon" /> {this.props.form.fileName}</span>
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentPdf">
                                <FormattedMessage id="draftPdfLabel" />
                            </UncontrolledTooltip>
                        </p>
                        }
                        <div id="sentDescription" className="sent-text-item" tabIndex={0}>
                            <i className="fas fa-lightbulb label-icon" />
                            <div className="sent-description-text" dangerouslySetInnerHTML={{ __html: this.props.form.description }} />
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentDescription">
                                <FormattedMessage id="draftDescription" />
                            </UncontrolledTooltip>
                        </div>
                    </div>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target="sentCompetition">
                        <FormattedMessage id="draftCompetitionTitle" />
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom-start" hideArrow target="sentTitle">
                        <FormattedMessage id="draftTitle" />
                    </UncontrolledTooltip>
                </section>
                <section className="sent-section">
                    <div className="sent-contact">
                            <p tabIndex={0} className="sent-text-item"><span id="sentTeam" className="sent-text-item"><i className="fas fa-users label-icon" />
                                {this.props.form.name} + {matesQuantity} { numberOfMates === 1 ? <FormattedMessage id="teamMember" /> :
                                    <FormattedMessage id="teamMembers" /> }</span></p>
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentTeam">
                                <FormattedMessage id="draftSenderName" /> + <FormattedMessage id="otherMembers" />
                            </UncontrolledTooltip>
                        <FormGroup>
                            <div tabIndex={0} className="sent-email-phone">
                                <p className="sent-text-item"><span id="sentEmail" className="sent-text-item"><i className="fas fa-envelope label-icon" /> {this.props.form.email}</span></p>
                                {this.props.form.phone !== '' ? <p className="sent-text-item"><span id="sentPhone" className="sent-text-item">
                                        <i className="fas fa-phone-square label-icon" /> {this.props.form.phone}</span></p> : ''}
                            </div>
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentEmail"><FormattedMessage id="draftSenderEmail" /></UncontrolledTooltip>
                            {this.props.form.phone !== '' ?
                            <UncontrolledTooltip placement="bottom-start" hideArrow target="sentPhone">
                                <FormattedMessage id="draftSenderPhone" />
                            </UncontrolledTooltip> : ''}
                        </FormGroup>
                        <FormGroup className="sender-school-role school-management row" id="sentSchool">
                            <img alt="Oppilaitos (School) logo" src={schoolImgSrc} />
                            <p className="sent-text-item role-text"><FormattedMessage id={roleLabel} />: {roleText}</p>
                            <UncontrolledTooltip target="sentSchool" placement="bottom-start" hideArrow>
                                <FormattedMessage id="draftSenderSchool" />
                            </UncontrolledTooltip>
                        </FormGroup>
                    </div>
              </section>
            </ModalBody>
        );
    }
}

SentDraftDetails.propTypes = {
    form: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ DraftActions: state.DraftActions });

export default connect(mapStateToProps)(SentDraftDetails);
