/* eslint-disable spaced-comment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { checkSession } from '../../../../actions/AuthenticationActions';
import { fetchCompetitions, updateCompetition, toggleIsRunning, addUsageRights, removeUsageRights,
    deleteCompetition } from '../../../../actions/CompetitionActions';
import { fetchUsers } from '../../../../actions/UserManagementActions';

class CompetitionListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCompetition: '',
            selectedCompetitionName: '',
            renameModal: false,
            usersInCompetition: [],
            userToModify: '',
            userListModal: false,
            renamedCompetitionFi: '',
            renamedCompetitionEn: '',
            deleteConfirmModal: false,
            deleteSuccesModal: false,
            deleteConfirmationChecked: false,
        };
        this.checkUserSession = this.checkUserSession.bind(this);
        // Rename
        this.renameCompetition = this.renameCompetition.bind(this);
        this.handleRenameInput = this.handleRenameInput.bind(this);
        this.toggleRenameModal = this.toggleRenameModal.bind(this);
        // Access
        this.fetchUsersFunction = this.fetchUsersFunction.bind(this);
        this.prepareModifyAccess = this.prepareModifyAccess.bind(this);
        this.modifyAccess = this.modifyAccess.bind(this);
        this.prepareUserListModal = this.prepareUserListModal.bind(this);
        this.toggleUserListModal = this.toggleUserListModal.bind(this);
        this.userListItem = this.userListItem.bind(this);
        // Visibility
        this.hideCompetition = this.hideCompetition.bind(this);
        // Delete
        this.deleteCompetition = this.deleteCompetition.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        // Fetch all
        this.fetchCompetitionsFunction = this.fetchCompetitionsFunction.bind(this);
        this.competitionListItem = this.competitionListItem.bind(this);
        // Bindings for buttons.
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.toggleDeleteSuccess = this.toggleDeleteSuccess.bind(this);
        this.checkDeleteConfirm = this.checkDeleteConfirm.bind(this);
    }

    componentDidMount() {
        this.checkUserSession();
        this.fetchCompetitionsFunction();
        this.fetchUsersFunction();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Display an alert according to update results.
        if (nextProps.competitions.deleteCompetitionStatus !== this.props.competitions.deleteCompetitionStatus) {
            if (nextProps.competitions.deleteCompetitionStatus === 2) {
                this.fetchCompetitionsFunction();
                alert('Hakukohteen poistamisessa tapahtui virhe! Ole hyvä ja yritä uudestaan.');
            }
            else if (nextProps.competitions.deleteCompetitionStatus === 3) {
                this.fetchCompetitionsFunction();
                this.fetchCompetitionsFunction();
                this.setState({
                    deleteSuccesModal: true,
                    deleteConfirmModal: false,
                });
            }
        }
        // Display an alert according to Toggle visibility results.
        if (nextProps.competitions.competitionToggleStatus !== this.props.competitions.competitionToggleStatus) {
            if (nextProps.competitions.competitionToggleStatus === 2) {
                alert('Hakukohteen näkyvyyden muuttamisessa tapahtui virhe, yritä päivitystä uudestaan.');
                window.location.reload(true);
            }
            /* Do we want to do anything special if success?
            else if (nextProps.competitions.competitionToggleStatus === 3) {
                this.setState({
                    isSuccessModalVisible: true,
                });
            }*/
        }
        // If new competition is added. Also perform checks on Comptition.jsx for resetting new names.
        if (nextProps.competitions.addCompetitionStatus !== this.props.competitions.addCompetitionStatus) {
            if (nextProps.competitions.addCompetitionStatus === 2) {
                alert('Hakukohteen lisäämisessä tapahtui virhe, yritä uudestaan.');
                window.location.reload(true);
            }
            else if (nextProps.competitions.addCompetitionStatus === 3) {
                this.fetchCompetitionsFunction();
            }
        }
        if (nextProps.competitions.competitionAddAccessStatus !== this.props.competitions.competitionAddAccessStatus) {
            if (nextProps.competitions.competitionAddAccessStatus === 2) {
                alert('Hakukohteen käyttöoikeuden muuttamisessa tapahtui virhe! Ole hyvä ja yritä uudestaan.');
            }
            else if (nextProps.competitions.competitionAddAccessStatus === 3) {
                // Push the modified user to the arrya, thus displaying it with correct status.
                this.state.usersInCompetition.push(this.state.userToModify);
            }
        }
        if (nextProps.competitions.competitionRemoveAccessStatus !== this.props.competitions.competitionRemoveAccessStatus) {
            if (nextProps.competitions.competitionRemoveAccessStatus === 2) {
                alert('Hakukohteen käyttöoikeuden muuttamisessa tapahtui virhe! Ole hyvä ja yritä uudestaan.');
            }
            else if (nextProps.competitions.competitionRemoveAccessStatus === 3) {
                // React does not like the idea of mutating an array.
                // Create a copy of the array without including the modified item.
                let array = [];
                for (let i = 0, len = this.state.usersInCompetition.length; i < len; i++) {
                    if (this.state.usersInCompetition[i] != this.state.userToModify) {
                        array.push(this.state.usersInCompetition[i]);
                    }
                }
                // Set as state array...
                this.setState({ usersInCompetition: array });
            }
        }
    }

    checkDeleteConfirm() {
        this.setState({
            deleteConfirmationChecked: !this.state.deleteConfirmationChecked
        });
    }

    toggleDeleteConfirmModal(props) {
        this.checkUserSession();
        if (!this.state.deleteConfirmModal) {
            if (props.competition != null && props.competition !== undefined) {
                this.setState({
                    selectedCompetition: props.competition._id,
                    selectedCompetitionName: props.competition.nameFi,
                });
            }
        }
        this.setState({
            deleteConfirmationChecked: false,
            deleteConfirmModal: !this.state.deleteConfirmModal,
        });
    }

    toggleDeleteSuccess() {
        this.setState({
            deleteSuccesModal: !this.state.deleteSuccesModal,
        });
    }

    fetchCompetitionsFunction() {
        const { dispatch } = this.props;
        dispatch(fetchCompetitions());
    }

    checkUserSession() {
        const { dispatch } = this.props;
        dispatch(checkSession());
    }

    renameCompetition() {
        this.checkUserSession();
        const formData = this.state;
        // Method to update competition
        const { dispatch } = this.props;
        if (this.state.renamedCompetitionFi !== '' || this.state.renamedCompetitionEn !== '') {
            dispatch(updateCompetition(formData));
            this.setState({
                renameModal: false,
            });
            this.fetchCompetitionsFunction();
        }
        else {
            alert('Syötä nimi suomeksi ja englanniksi, tarkista kentät ja yritä uudestaan.');
        }
    }

    handleRenameInput() {
        this.setState({ renamedCompetitionFi: this.refs.renamedCompetitionFi.value });
        this.setState({ renamedCompetitionEn: this.refs.renamedCompetitionEn.value });
    }

    toggleRenameModal(props) {
        this.checkUserSession();
        if (!this.state.renameModal) {
            this.setState({
                selectedCompetition: props._id,
                renamedCompetitionFi: props.nameFi,
                renamedCompetitionEn: props.nameEn,
            });
        }
        this.setState({
            renameModal: !this.state.renameModal
        });
    }

    fetchUsersFunction() {
        const { dispatch } = this.props;
        dispatch(fetchUsers());
    }

    prepareUserListModal(props) {
        for (let i = 0, len = props.competition.users.length; i < len; i++) {
            this.state.usersInCompetition.push(props.competition.users[i]);
        }
        this.setState({
            selectedCompetition: props.competition._id,
            selectedCompetitionName: props.competition.nameFi,
        });
        this.toggleUserListModal();
    }

    toggleUserListModal() {
        // If we are closing the modal, empty the associated states, array is important...
        if (this.state.userListModal) {
            this.setState({
                selectedCompetitionName: '',
                usersInCompetition: [],
                userToModify: '',
            });
        }
        this.fetchCompetitionsFunction();
        this.setState({
            userListModal: !this.state.userListModal
        });
    }

    prepareModifyAccess(props) {
        this.setState({
            userToModify: props.user.email,
            },
            // Callback to handleValidSubmit once setState is done for.
            this.modifyAccess
        );
    }

    modifyAccess() {
        const { dispatch } = this.props;
        // If user has access to the competition remove it.
        for (let i = 0, len = this.state.usersInCompetition.length; i < len; i++) {
            if (this.state.userToModify == this.state.usersInCompetition[i]) {
                dispatch(removeUsageRights(this.state));
                return;
            }
        }
        dispatch(addUsageRights(this.state));
    }

    hideCompetition(props) {
        this.checkUserSession();
        const { dispatch } = this.props;
        dispatch(toggleIsRunning(props.competition));
        this.fetchCompetitionsFunction();
    }

    deleteCompetition() {
        const toDelete = { selectedCompetition: this.state.selectedCompetition, userEmail: this.props.authentication.email };
        const { dispatch } = this.props;
        dispatch(deleteCompetition(toDelete));
    }

    competitionListItem(props) {
        // Bindings
        const renameCompetitionBtn = this.toggleRenameModal.bind(this, props.competition);
        const modifyCompetitionAccessBtn = this.prepareUserListModal.bind(this, props);
        const hideCompetitionBtn = this.hideCompetition.bind(this, props);
        const deleteCompetitionBtn = this.toggleDeleteConfirmModal.bind(this, props);
        // Button elements
        const renameBtn = <Button onClick={renameCompetitionBtn} className="edit-icon"><i className="fas fa-pencil-alt label-icon" /></Button>;
        const modifyAccessBtn = <Button color="info" onClick={modifyCompetitionAccessBtn}>Muokkaa</Button>;
        let visibilityBtn = <Button color="secondary" onClick={hideCompetitionBtn}>Piilotettu - Aktivoi</Button>;
        const deleteBtn = <Button color="danger" onClick={deleteCompetitionBtn}>Poista</Button>;
        if (props.competition.isRunning) {
            visibilityBtn = <Button color="warning" onClick={hideCompetitionBtn}>Aktiivinen - Piilota</Button>;
        }
        return (
            <tr>
                <td>{props.competition.nameFi + ' – ' + props.competition.nameEn} {renameBtn}</td>
                <td>{modifyAccessBtn}</td>
                <td>{visibilityBtn}</td>
                <td>{deleteBtn}</td>
            </tr>
        );
    }

    userListItem(props) {
        const modifyAccessButton = this.prepareModifyAccess.bind(this, props);
        let modifyAccessBtn = <Button color="secondary" onClick={modifyAccessButton}>Ei oikeuksia - Myönnä oikeudet</Button>;

        for (let i = 0, len = this.state.usersInCompetition.length; i < len; i++) {
            // Note: don't use === here, it wont match.
            if (props.user.email == this.state.usersInCompetition[i]) {
                modifyAccessBtn = <Button color="warning" onClick={modifyAccessButton}>Katseluoikeudet - Poista oikeudet</Button>;
            } else {
                //console.log("EM: " + props.user.email + " ARR: " + this.state.usersInCompetition[i]);
            }
        }
        return (
            <tr>
                <td>{props.user.email}</td>
                <td>{modifyAccessBtn}</td>
            </tr>
        );
    }

    render() {
        const toggleConfirmDeleteBtn = this.toggleDeleteConfirmModal.bind(this);
        return (
            <div>
                <div>
                    <Table>
                        <tbody>
                        <tr>
                            <th>Nimi</th>
                            <th>Oikeudet</th>
                            <th>Näkyvyys</th>
                            <th>Poistaminen</th>
                        </tr>
                        {
                            this.props.competitions.competitionList.map(competition => (
                                <this.competitionListItem
                                    competition={competition}
                                    key={competition._id}
                                />
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
                {/* Modal for renaming a competition */}
                <Modal isOpen={this.state.renameModal} toggle={this.toggleDeleteConfirmModal}>
                    <ModalHeader className="modal-success">Hakukohteen uudelleennimeäminen</ModalHeader>
                    <br />
                    <ModalBody>
                        <div>
                            <Label className="settings-category">Muokattu nimi suomeksi</Label>
                            <input type="text" value={this.state.renamedCompetitionFi} className="form-field"
                                   onChange={this.handleRenameInput} ref="renamedCompetitionFi"
                            />
                            <Label className="settings-category">Muokattu nimi englanniksi</Label>
                            <input type="text" value={this.state.renamedCompetitionEn} className="form-field"
                                   onChange={this.handleRenameInput} ref="renamedCompetitionEn"
                            />
                        </div>
                        <br />
                        <Button color="info" onClick={this.toggleRenameModal}>Peruuta</Button>
                        <Button color="success" onClick={this.renameCompetition}>Tallenna</Button>
                        <br />
                    </ModalBody>
                </Modal>
                {/* Modal for modifying user access */}
                <Modal className="modal-lg always-lg" isOpen={this.state.userListModal} toggle={this.userListModal} backdrop="static">
                    <ModalHeader toggle={this.toggleUserListModal}>Käyttöoikeuksien muokkaus</ModalHeader>
                    <ModalBody>
                        <div>
                            <h4>Muokattava hakukohde: { this.state.selectedCompetitionName }</h4>
                            <Table>
                                <tbody>
                                <tr>
                                    <th>Sähköposti</th>
                                    <th>Oikeudet</th>
                                </tr>
                                {
                                    this.props.userManagement.userList.map(user => (
                                        <this.userListItem
                                            user={user}
                                            key={user.email}
                                        />
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>
                    <br />
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleUserListModal}>Sulje</Button>
                    </ModalFooter>
                </Modal>
                {/* Modal for confirming delete on a competition */}
                <Modal isOpen={this.state.deleteConfirmModal} toggle={this.toggleDeleteConfirmModal}>
                    <ModalHeader className="confirm-delete">Hakukohteen poistamisen vahvistus</ModalHeader>
                    <br />
                    <ModalBody>
                        { this.state.selectedCompetition !== '' ?
                            <p>Olet poistamassa hakukohdetta: {this.state.selectedCompetitionName}
                                <br />Kaikki kohteeseen liitetyt hakemukset poistetaan.</p> :
                            null }
                        <br />
                        <Form>
                            <FormGroup check>
                                <Input type="checkbox" className="confirm-delete-checkbox" checked={this.state.deleteConfirmationChecked}
                                       onChange={this.checkDeleteConfirm.bind(this)} />{' '}
                                <div style={{ marginLeft: 40 + 'px' }}>
                                    <strong>Haluan varmasti poistaa tämän hakukohteen.</strong>
                                </div>
                                <br />
                            </FormGroup>
                            <br />
                            <Button color="info" onClick={toggleConfirmDeleteBtn}>Peruuta</Button>
                            { this.state.deleteConfirmationChecked === true ?
                                <Button color="danger" style={{ float: 'right' }} onClick={this.deleteCompetition}>Poista hakukohde
                                </Button> : <Button color="danger" style={{ float: 'right' }} disabled >Poista hakukohde</Button> }
                        </Form>
                    </ModalBody>
                </Modal>
                {/* Modal for success message */}
                <Modal isOpen={this.state.deleteSuccesModal} toggle={this.toggleDeleteSuccess}>
                    <ModalHeader className="modal-success">Hakukohteen poistaminen onnistui</ModalHeader>
                    <br />
                    <ModalBody><p>Hakukohde poistettiin onnistuneesti.</p></ModalBody>
                    <ModalFooter className="modal-success">
                        <Button color="primary" onClick={this.toggleDeleteSuccess}>OK</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

CompetitionListPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

CompetitionListPage.contextTypes = {
    router: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        competitions: state.competitions,
        userManagement: state.userManagement,
    };
}

export default connect(mapStateToProps)(CompetitionListPage);
