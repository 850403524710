import 'whatwg-fetch';
import { decrementProgress, incrementProgress } from './ProgressActions';

// Action Creators
export const competitionAddAttempt = () => ({ type: 'COMPETITION_ADD_ATTEMPT' });
export const competitionAddFailure = error => ({ type: 'COMPETITION_ADD_FAILURE', error });
export const competitionAddSuccess = json => ({ type: 'COMPETITION_ADD_SUCCESS', json });
// Rename
export const competitionUpdateAttempt = () => ({ type: 'COMPETITION_UPDATE_ATTEMPT' });
export const competitionUpdateFailure = error => ({ type: 'COMPETITION_UPDATE_FAILURE', error });
export const competitionUpdateSuccess = () => ({ type: 'COMPETITION_UPDATE_SUCCESS' });
// Toggle
export const competitionToggleAttempt = () => ({ type: 'COMPETITION_TOGGLE_ATTEMPT' });
export const competitionToggleFailure = error => ({ type: 'COMPETITION_TOGGLE_FAILURE', error });
export const competitionToggleSuccess = () => ({ type: 'COMPETITION_TOGGLE_SUCCESS' });
// Add access
export const competitionAddAccessAttempt = () => ({ type: 'COMPETITION_ADD_ACCESS_ATTEMPT' });
export const competitionAddAccessFailure = error => ({ type: 'COMPETITION_ADD_ACCESS_FAILURE', error });
export const competitionAddAccessSuccess = () => ({ type: 'COMPETITION_ADD_ACCESS_SUCCESS' });
// Remove access
export const competitionRemoveAccessAttempt = () => ({ type: 'COMPETITION_REMOVE_ACCESS_ATTEMPT' });
export const competitionRemoveAccessFailure = error => ({ type: 'COMPETITION_REMOVE_ACCESS_FAILURE', error });
export const competitionRemoveAccessSuccess = () => ({ type: 'COMPETITION_REMOVE_ACCESS_SUCCESS' });
// Fetch all
export const competitionsFetchAttempt = () => ({ type: 'COMPETITIONS_FETCH_ATTEMPT' });
export const competitionsFetchFailure = error => ({ type: 'COMPETITIONS_FETCH_FAILURE', error });
export const competitionsFetchSuccess = json => ({ type: 'COMPETITIONS_FETCH_SUCCESS', json });
// Fetch single
export const fetchCompetitionsWithAccessAttempt = () => ({ type: 'COMPETITIONS_WITH_ACCESS_FETCH_ATTEMPT' });
export const fetchCompetitionsWithAccessFailure = error => ({ type: 'COMPETITIONS_WITH_ACCESS_FETCH_FAILURE', error });
export const fetchCompetitionWithAccessSuccess = json => ({ type: 'COMPETITIONS_WITH_ACCESS_FETCH_SUCCESS', json });
// For deleting a competition.
export const deleteCompetitionAttempt = () => ({ type: 'DELETE_COMPETITION_ATTEMPT' });
export const deleteCompetitionFailure = error => ({ type: 'DELETE_COMPETITION_FAILURE', error });
export const deleteCompetitionSuccess = json => ({ type: 'DELETE_COMPETITION_SUCCESS', json });

// Add competition
export function addCompetition(competitionData) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());
        // register that add draft attempt is being made
        dispatch(competitionAddAttempt());
        // contact API
        fetch(
            '/api/competitions/addCompetition',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(competitionData),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then(async (json) => {
                if (json && json.success) {
                    dispatch(competitionAddSuccess());
                } else {
                    dispatch(competitionAddFailure(new Error(json.error.message ? 'There was an error in saving the draft: ' : json.error)));
                }
            })
            .catch((error) => {
                dispatch(competitionAddFailure(new Error(error)));
            });

        // turn off spinner
        return dispatch(decrementProgress());
    };
}

// Fetch all competitions
export function fetchCompetitions() {
    return async (dispatch) => {
        dispatch(incrementProgress());
        dispatch(competitionsFetchAttempt());
        fetch(
            // contact to
            '/api/competitions/getCompetitions',
            // what to send
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return null;
        })
            .then(async (json) => {
                if (json) {
                    dispatch(competitionsFetchSuccess(json));
                } else {
                    dispatch(competitionsFetchFailure(new Error('Fetch competitions failed')));
                }
            })
            .catch((error) => {
                dispatch(competitionsFetchFailure(new Error(error)));
            });
        return dispatch(decrementProgress());
    };
}

// Fetch all running competitions
export function fetchRunningCompetitions() {
    return async (dispatch) => {
        dispatch(incrementProgress());
        dispatch(competitionsFetchAttempt());
        fetch(
            // contact to
            '/api/competitions/getRunningCompetitions',
            // what to send
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return null;
        })
            .then(async (json) => {
                if (json) {
                    dispatch(competitionsFetchSuccess(json));
                } else {
                    dispatch(competitionsFetchFailure(new Error('Fetch competitions failed')));
                }
            })
            .catch((error) => {
                dispatch(competitionsFetchFailure(new Error(error)));
            });
        return dispatch(decrementProgress());
    };
}

// Add competition
export function fetchCompetitionsWithAccess(email) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());
        // register that add draft attempt is being made
        dispatch(fetchCompetitionsWithAccessAttempt());
        // contact API
        fetch(
            '/api/competitions/getWithAccess',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(email),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return null;
        })
            .then(async (json) => {
                if (json) {
                    dispatch(fetchCompetitionWithAccessSuccess(json));
                } else {
                    dispatch(fetchCompetitionsWithAccessFailure(new Error('Fetch competitions failed')));
                }
            })
            .catch((error) => {
                dispatch(fetchCompetitionsWithAccessFailure(new Error(error)));
            });
        return dispatch(decrementProgress());
    };
}

// Rename competition
export function updateCompetition(competition) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());

        // register that add draft attempt is being made
        dispatch(competitionUpdateAttempt());

        // contact API
        await fetch(
            '/api/competitions/updateCompetition',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(competition),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then((json) => {
                if (json.success) {
                    return dispatch(competitionUpdateSuccess());
                }
                return dispatch(competitionUpdateFailure(new Error(json.error.message ? 'Competition failed to update.' : json.error)));
            })
            .catch((error) => {
                return dispatch(competitionUpdateFailure(new Error(error)));
            });

        // turn off spinner
        return dispatch(decrementProgress());
    };
}
// toggleIsRunning competition
export function toggleIsRunning(competition) {
    return async (dispatch) => {
        // turn on spinner & dispatch attempt.
        dispatch(incrementProgress());
        dispatch(competitionToggleAttempt());
        // contact API
        await fetch(
            '/api/competitions/toggleIsRunning',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(competition),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then((json) => {
                if (json.success) {
                    return dispatch(competitionToggleSuccess());
                }
                return dispatch(competitionToggleFailure(new Error(json.error.message ? 'Competition failed to update.' : json.error)));
            })
            .catch((error) => {
                return dispatch(competitionToggleFailure(new Error(error)));
            });

        // turn off spinner
        return dispatch(decrementProgress());
    };
}

// addUsageRights
export function addUsageRights(competition) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());

        // register that add draft attempt is being made
        dispatch(competitionAddAccessAttempt());

        // contact API
        await fetch(
            '/api/competitions/addUsageRights',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(competition),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then((json) => {
                if (json.success) {
                    return dispatch(competitionAddAccessSuccess());
                }
                return dispatch(competitionAddAccessFailure(new Error(json.error.message ? 'Competition failed to update.' : json.error)));
            })
            .catch((error) => {
                return dispatch(competitionAddAccessFailure(new Error(error)));
            });

        // turn off spinner
        return dispatch(decrementProgress());
    };
}

// removeUsageRights
export function removeUsageRights(competition) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());

        // register that add draft attempt is being made
        dispatch(competitionRemoveAccessAttempt());

        // contact API
        await fetch(
            '/api/competitions/removeUsageRights',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(competition),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then((json) => {
                if (json.success) {
                    return dispatch(competitionRemoveAccessSuccess());
                }
                return dispatch(competitionRemoveAccessFailure(new Error(json.error.message ? 'Competition failed to update.' : json.error)));
            })
            .catch((error) => {
                return dispatch(competitionRemoveAccessFailure(new Error(error)));
            });

        // turn off spinner
        return dispatch(decrementProgress());
    };
}

export function deleteCompetition(data) {
    return async (dispatch) => {
        // turn on spinner
        dispatch(incrementProgress());
        // register that add draft attempt is being made
        dispatch(deleteCompetitionAttempt());
        // contact API
        fetch(
            // contact to
            '/api/competitions/deleteCompetition',
            // what to send
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            },
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return null;
        })
            .then(async (json) => {
                if (json) {
                    dispatch(deleteCompetitionSuccess(json));
                } else {
                    dispatch(deleteCompetitionFailure(new Error('Delete failed')));
                }
            })
            .catch((error) => {
                dispatch(deleteCompetitionFailure(new Error(error)));
            });
        return dispatch(decrementProgress());
    };
}

