import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Button, Label } from 'reactstrap';
import Info from './Info';
import UserManagement from './UserManagement';
import CompetitionManagement from './Competition';
import { checkSession } from '../../../../actions/AuthenticationActions';
import { toggleEmailNotifications } from '../../../../actions/UserManagementActions';
import { createHash } from '../../../../actions/PassResetActions';

class SettingsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: this.props.authentication.email,
            userNotifications: this.props.authentication.emailNotifications,
            notificationsSuccesModal: false,
            hasSentResetMail: false,
        };
        this.checkUserSession = this.checkUserSession.bind(this);
        this.toggleEmailNotifications = this.toggleEmailNotifications.bind(this);
        this.sendToggleToDB = this.sendToggleToDB.bind(this);
        this.toggleNested = this.toggleNested.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.sendPasswordResetLink = this.sendPasswordResetLink.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.checkUserSession();
    }

    // TO DO: We just reload the page to avoid dealing with re-rendering and re-doing the function with possible re-toggle.
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.userManagement.toggleNotificationsStatus !== this.props.userManagement.toggleNotificationsStatus) {
            // Display an alert according to update results.
            if (nextProps.userManagement.toggleNotificationsStatus === 2) {
                alert('Sähköpostiasetuksien muuttamisessa tapahtui virhe.');
                window.location.reload(true);
            }
            else if (nextProps.userManagement.toggleNotificationsStatus === 3) {
                this.toggleNested();
            }
        }
        if (nextProps.userManagement.toggleNotificationsStatus !== this.props.userManagement.toggleNotificationsStatus) {
            // Display an alert according to update results.
            if (nextProps.userManagement.toggleNotificationsStatus === 2) {
                alert('Sähköpostiasetuksien muuttamisessa tapahtui virhe.');
                window.location.reload(true);
            }
            else if (nextProps.userManagement.toggleNotificationsStatus === 3) {
                this.toggleNested();
            }
        }
    }

    reloadPage() {
        window.location.reload(true);
    }

    sendToggleToDB() {
        const { dispatch } = this.props;
        const user = this.state;
        dispatch(toggleEmailNotifications(user));
    }

    toggleEmailNotifications() {
        if (this.props.authentication.emailNotifications)
        {
            this.setState({
                userNotifications: false,
            }
            , this.sendToggleToDB
            );
        }
        else if (!this.props.authentication.emailNotifications)
        {
            this.setState({
                userNotifications: true,
                }
                , this.sendToggleToDB
            );
        }
        else
        {
            alert('Sähköpostiasetuksien muuttamisessa tapahtui virhe.');
            window.location.reload(true);
        }
        this.checkUserSession();
    }

    sendPasswordResetLink() {
        const { dispatch } = this.props;
        dispatch(createHash({ email: this.state.userEmail }));
    }

    checkUserSession() {
        const { dispatch } = this.props;
        dispatch(checkSession());
    }

    toggleNested() {
        this.setState({
            notificationsSuccesModal: !this.state.notificationsSuccesModal,
        });
    }

    render() {
        let adminSettings = '';
        let passwordResetDate = '-';
        if (this.props.authentication.isAdmin) {
            adminSettings = (<div>
                <br />
                <h2 className="admin-settings-text">Admin-käyttäjän asetukset</h2>
                <Info infos={this.props.infos} />
                <UserManagement />
                <CompetitionManagement competitions={this.props.competitions} />
            </div>);
        }
        if (this.props.authentication.lastPasswordReset !== undefined) {
            let resetDate = new Date(this.props.authentication.lastPasswordReset);
            if (resetDate !== new Date()) {
                // Get dd.mm.yyyy hh:mm
                // January = 0.
                const days = (resetDate.getDate());
                const months = (resetDate.getMonth() + 1);
                const years = (resetDate.getFullYear());
                passwordResetDate = days + '.' + months + '.' + years;
            }
        }
        return (
            <div>
                <br />
                <FormGroup check onChange={this.toggleEmailNotifications.bind(this)}>
                    <Input className="email-notification-toggler" id="emailToggler" type="checkbox" defaultChecked={this.props.authentication.emailNotifications} />{' '}
                    <Label className="email-notification-toggler-label" for="emailToggler">Lähetä sähköposti-ilmoitus uusista hakemuksista</Label>
                </FormGroup>
                <FormGroup check onChange={this.toggleEmailNotifications.bind(this)}>
                    <Label className="password-reset-button-settings-label" for="passwordResetBtn"><i className="fas fa-key label-icon" /> Salasana vaihdettu: {passwordResetDate}</Label>
                    <Button className="password-reset-button-settings" color="primary" id="passwordResetBtn"
                            disabled={this.props.passwordManagement.isPasswordReset === 3} onClick={this.sendPasswordResetLink}>
                        {this.props.passwordManagement.isPasswordReset === 3 ? 'Salasanan muokkauslinkki lähetettiin sähköpostiin' : 'Lähetä salasanan muokkauslinkki sähköpostiin' }</Button>
                    {this.props.passwordManagement.isPasswordReset === 2 ? <p>Salasanan muokkauslinkin lähettämisessä tapahtui virhe, yritä uuudestaan.</p> : '' }
                </FormGroup>
                <Modal isOpen={this.state.notificationsSuccesModal} toggle={this.toggleNested}>
                    <ModalHeader className="modal-success">Päivittäminen onnistui</ModalHeader>
                    <br />
                    <ModalBody><p>Sähköpostiasetukset päivitettiin onnistuneesti.</p></ModalBody>
                    <br />
                    <ModalFooter className="modal-success">
                        <Button color="primary" onClick={this.reloadPage}>OK</Button>
                    </ModalFooter>
                </Modal>
                {adminSettings}
            </div>
        );
    }
}

// Retrieve data from store as props
function mapStateToProps(state) {
    return {
        infos: state.infos,
        competitions: state.competitions,
        authentication: state.authentication,
        userManagement: state.userManagement,
        passwordManagement: state.passwordManagement,
    };
}

SettingsManagement.propTypes = {
    dispatch: PropTypes.func.isRequired,
};


SettingsManagement.contextTypes = {
    router: PropTypes.object,
};

export default connect(mapStateToProps)(SettingsManagement);
