import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DraftCreateWidget from './DraftCreateWidget';
import { fetchInfo } from '../../../../actions/InfoActions';

class DraftForm extends Component {
  constructor(props) {
    super(props);
    this.fetchInfoFunction = this.fetchInfoFunction.bind(this);
  }

  componentDidMount() {
    this.fetchInfoFunction();
  }

  fetchInfoFunction() {
    const { dispatch } = this.props;
    dispatch(fetchInfo());
  }

  render() {
    const { draft, intl, infos } = this.props;
    const FI = infos.data.infoFi;
    const EN = infos.data.infoEn;
    const languageNodes = this.props.intl.enabledLanguages.map(
        lang => (<li key={lang} > <button tabIndex={0} onClick={() => this.props.switchLanguage(lang)}
                              className={lang === this.props.intl.locale ? 'language-selector selected' : 'language-selector'}>{lang}</button></li>)
    );
    return (
      <div>
        <div className="jumbotron form-info">
          <div className="lang-and-intro-title">
            <nav className="language-nav">
            <ul className="language-switcher col-md-6 col-sm-12">
              {languageNodes}
            </ul>
            </nav>
            <h1 tabIndex={0} className="card-title info-title"><span><i className="fas fa-info-circle label-icon" /> <FormattedMessage id="draftFormInfo" /></span></h1>
          </div>
          <div className="form-help" tabIndex={0} dangerouslySetInnerHTML={{ __html: intl.locale === 'fi' ? FI : EN }} />
        </div>
          <DraftCreateWidget draft={draft} intl={intl} termsFi={infos.data.termsFi} termsEn={infos.data.termsEn} />
      </div>
    );
  }
}

// actions required to provide data for this component to render in sever side.
DraftForm.need = [() => { return fetchInfo(); }];

// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    infos: state.infos
  };
}

DraftForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

DraftForm.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps)(DraftForm);
