/** ************************************
 * We want to show messages in UI for fail/succes, but not either of them by default.
 * Thus we create 4 states for the following:
 *
 * ************************************
 * isPasswordReset:
 * 0 = Not attempted. (default)
 * 1 = Attempt.
 * 2 = Failed.
 * 3 = Success.
 *  ***********************************
 * passwordChangeStatus:
 * 0 = Not attempted. (default)
 * 1 = Attempt.
 * 2 = Failed.
 * 3 = Success.
 ************************************* */
const initialState = {
    passwordChangeStatus: 0,
    isPasswordReset: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'ACCOUNT_PASSWORD_RESET_HASH_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.isPasswordReset = 1;
            return newState;
        }
        case 'ACCOUNT_PASSWORD_RESET_HASH_FAILURE': {
            const newState = Object.assign({}, state);
            newState.isPasswordReset = 2;
            return newState;
        }
        case 'ACCOUNT_PASSWORD_RESET_HASH_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.isPasswordReset = 3;
            return newState;
        }
        case 'ACCOUNT_PASSWORD_SAVE_ATTEMPT': {
            const newState = Object.assign({}, state);
            newState.passwordChangeStatus = 1;
            return newState;
        }
        case 'ACCOUNT_PASSWORD_SAVE_FAILURE': {
            const newState = Object.assign({}, state);
            newState.passwordChangeStatus = 2;
            return newState;
        }
        case 'ACCOUNT_PASSWORD_SAVE_SUCCESS': {
            const newState = Object.assign({}, state);
            newState.passwordChangeStatus = 3;
            return newState;
        }
        default: {
            return state;
        }
    }
}
