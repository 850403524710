export default {
  locale: 'en',
  messages: {
    siteTitle: 'Draft application form',
    switchLanguage: ' ',
    draftFormInfo: 'Instructions',
    draftApplication: 'Draft application',
    draftCompetitionTitle: 'Competition',
    draftCompetitionDefault: 'Select a competition',
    draftCompetitionInvalid: 'Select the competition you are applying to. If none are available, there are no ongoing Draft -programs.',
    inputTooLong: 'Input is too long.',
    draftContactDetails: 'Contact details',
    draftContactInfoText: 'At least one member of the team must be a student or an employee at Karelia University of ' +
        'Applied Sciences, University of Eastern Finland or North Karelia Municipal Education and Training Consortium Riveria. ' +
        'The details of one such person must be entered.',
    draftSenderName: 'Your first and last name',
    draftSenderNameInvalid: 'First and last names must be at least 5 characters combined.',
    draftSenderEmail: 'Your own email address',
    draftSenderEmailInvalid: 'Email is invalid.',
    draftSenderConfirmEmail: 'Confirm email address',
    draftSenderConfirmEmailInvalid: 'Emails do not match.',
    draftSenderPhone: 'Phone',
    draftSenderPhoneInvalid: 'Phone number must contain at least 6 characters of which 3 are adjacent numbers.',
    draftSenderIsStudent: 'I am a student',
    yes: 'Yes',
    no: 'No',
    draftSenderSchool: 'School and role',
    karelia: 'Karelia University of Applied Sciences',
    uef: 'University of Eastern Finland',
    riveria: 'North Karelia Municipal Education and Training Consortium Riveria',
    draftSenderSchoolInvalid: 'Please write the name of the school you are representing.',
    draftSenderRole: 'Role at the school',
    draftSenderStudent: 'Student',
    draftSenderStaff: 'Staff',
    draftSenderAlumni: 'Alumni',
    draftSenderStudentOrAlumni: 'Study programme',
    draftSenderStaffPosition: 'Job',
    draftSenderOrganisationInvalid: "Write your answer, you don't need to tell about the team.",
    draftMembers: 'Team size',
    otherMembers: 'other team members',
    draftMembersDefault: 'Select the amount of members in your team',
    persons: 'members',
    teamMembers: 'team members.',
    teamMember: 'team member.',
    draftMembersInvalid: 'The minimum team size for participation is two members.',
    draftTitle: 'Name of the idea',
    draftTitleInvalid: 'Title must be at least 5 characters long.',
    draftDescription: 'Idea description ',
    draftDescriptionInvalid: 'The description is not long enough.',
    draftHeardFrom: 'How did you hear about the Draft Program?',
    draftHeardFromInvalid: 'Input is not long enough.',
    draftPdfLabel: 'Attachment',
    draftPdfInfo: 'Drop the file here or click to open the file browser.',
    draftPdfExtension: 'Only PDF files are accepted.',
    draftPdfRejected: 'Rejected file',
    draftPdfRejectedAlert: 'The file was rejected, it is too large or not in the .pdf format.',
    draftFinalize: 'Finalizing',
    draftTerms: 'I accept the terms & conditions and the security policy',
    termsModalTitle: 'Terms & conditions and security policy',
    termsTitle: 'Terms & conditions',
    cookiesTitle: 'Security policy',
    cookiesInfo: 'Some of the pages functionality depends on the usage of cookies.\n\n' +
    'Google uses cookies to provide the reCAPTCHA service used to protect against forms sent by bots.\n' +
    'More information of these cookies can be found from the links attached to the reCAPTCHA logo.\n\n' +
    'TinyMCE is a text editor, which is used to write the description. TinyMCE cookies are used to change the size of the text-area ' +
    'dynamically.',
    submit: 'Submit',
    formIncomplete: 'Please make sure all fields are filled out correctly.',
    submitError: 'There was an error in submitting the form, please try again.',
    competitionError: 'Error! The selected competition could not be found. It was apparently removed while the form was being filled.',
    descriptionTooLong: 'Error! The description exceeds the editors maximum limit of 75000 characters.',
    close: 'Close',
    formSubmitted: 'Form has been submitted',
    formSentText: 'Your application has been sent. You will receive an confirmation email, but it does not contain the application ' +
    'details, thus you should save any details you wish to save before closing this popup.\nIf you notice an error in the details, please' +
    ' contact: ',
  },
};
